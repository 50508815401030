import {
  TertiaryButton,
  FieldHint,
  Label,
  Input,
  LocationSearchInput,
  Paragraph,
  PhoneInput,
  PrimaryButton,
} from 'shared/components/atoms'
import { Modal } from 'shared/components/molecules'
import { ContentWrapper } from 'components/ContentWrapper'
import { useForm } from 'shared/hooks'
import { useManageProfile } from 'profile/hooks'
import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { PositionsSlug } from 'modules/shared/enums'

import { buildSchema } from '.'
import { UpdateProfileAboutSchema } from 'profile/domain'
import { useEffect } from 'react'

interface AboutProps {
  profile: ProfileOverview
  handleClose(): void
}

export const About = ({ handleClose, profile }: AboutProps) => {
  const { updateProfileAbout, isLoading } = useManageProfile(profile.id)

  const isProductDesigner = Boolean(
    profile.interestRoles.find(({ roleType }) =>
      [PositionsSlug.ProductDesigner, PositionsSlug.Design].includes(roleType),
    ),
  )

  const defaultValues: UpdateProfileAboutSchema = {
    firstName: profile.firstName,
    lastName: profile.lastName,
    location: profile.location,
    personalSite: profile.personalSite,
    gitRepositoriesUrl: profile.gitRepositoriesUrl,
    whatsAppNumber: profile.whatsAppNumber,
  }

  const { register, handleSubmit, setValue, getError, watch, isDirty } = useForm({
    schema: buildSchema(isProductDesigner),
    defaultValues,
  })
  const location = watch('location')

  const onSubmit = handleSubmit(async (payload) => {
    await updateProfileAbout(payload as UpdateProfileAboutSchema)
    handleClose()
  })

  useEffect(() => {
    /*
    This prevents closing the suggestions dropdown when clicking an option. 
    That issues only happens when using the LocationSearchInput inside a modal.
    */
    document.body.classList.add('!pointer-events-auto')

    return () => {
      document.body.classList.remove('!pointer-events-auto')
    }
  }, [])

  return (
    <Modal
      handleClose={handleClose}
      showDismissalConfirmation={isDirty}
      title="About"
      content={
        <form onSubmit={onSubmit} id="about-form">
          <ContentWrapper className="flex flex-col gap-5 sm:flex-row sm:gap-6 mt-4" isForm>
            <div className="flex-grow">
              <Label htmlFor="firstName">First name</Label>
              <Input register={register} name="firstName" />
            </div>
            <div className="flex-grow">
              <Label htmlFor="lastName">Last name</Label>
              <Input register={register} name="lastName" />
            </div>
          </ContentWrapper>
          <ContentWrapper isForm>
            <Label htmlFor="location">Where do you live?</Label>
            <LocationSearchInput
              name="location"
              setValue={setValue}
              error={getError('location')}
              location={location}
            />
          </ContentWrapper>
          <ContentWrapper isForm>
            <Label htmlFor="personalSite" isOptional={!isProductDesigner}>
              {isProductDesigner ? 'Portfolio link' : 'Personal website'}
            </Label>
            <Input register={register} name="personalSite">
              {isProductDesigner ? (
                <Paragraph weight="font-normal" size="body-sm" className="text-neutral-dark mt-2">
                  This doesn't need to be a formal portfolio website. You can send us any link that
                  contains your most relevant work. A Figma or Google Slides link with some of your
                  most recent work is great too! Please make sure the link is public and not
                  password protected.
                </Paragraph>
              ) : (
                <></>
              )}
            </Input>
          </ContentWrapper>
          <ContentWrapper isForm>
            <Label htmlFor="gitRepositoriesUrl" isOptional>
              GitHub / GitLab
            </Label>
            <Input register={register} name="gitRepositoriesUrl" />
          </ContentWrapper>
          <ContentWrapper isForm>
            <Label htmlFor="whatsAppNumber" isOptional>
              WhatsApp number
            </Label>
            <PhoneInput
              register={register}
              watch={watch}
              setValue={setValue}
              name="whatsAppNumber"
              id="whatsAppNumber"
              placeholder="+55 12 34567 8910"
            />
            <FieldHint>
              Strider will only use this number to contact you about relevant matching
              opportunities. We won’t spam you or share your number.
            </FieldHint>
          </ContentWrapper>
        </form>
      }
      footer={
        <>
          <TertiaryButton size="md" onClick={handleClose} className="w-full md:w-fit">
            Cancel
          </TertiaryButton>
          <PrimaryButton
            size="md"
            type="submit"
            form="about-form"
            className="w-full md:w-fit"
            isLoading={isLoading}
          >
            Save
          </PrimaryButton>
        </>
      }
    />
  )
}
