import { Text, TertiaryButton } from 'shared/components/atoms'
import { ReactComponent as CloseIcon } from 'icons/close.svg'
import { Link } from 'react-router-dom'
import { ReactComponent as RightArrow } from 'icons/right-arrow.svg'
import { clientReferralBonus } from 'pages/Referrals/exports/consts'

interface Props {
  onClick: () => void
}

export const ReferralBanner = ({ onClick }: Props) => (
  <div className="relative mb-6">
    <div className="absolute h-full w-full rounded-lg bg-gradient-to-r from-warning-medium to-brand-dark opacity-[0.08]"></div>
    <div className="flex items-center justify-between p-6 rounded-lg">
      <Text className="z-10 flex items-center">
        Know someone who's hiring? Refer them to Strider and earn {clientReferralBonus}.{' '}
        <Link to="/referrals" target="_blank" className="text-info-dark flex items-center gap-1">
          Learn more
          <RightArrow className="stroke-info-dark h-4 w-4" />
        </Link>
      </Text>
      <TertiaryButton
        className="hover:bg-transparent"
        size="sm"
        icon={<CloseIcon className="!w-6 !h-6" />}
        onClick={onClick}
      />
    </div>
  </div>
)
