import { useContext, useEffect, useMemo, useState } from 'react'

import {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  Spinner,
  StepsWizard,
} from 'shared/components/atoms'
import { AlertDialog, Modal } from 'shared/components/molecules'
import { Text } from 'components/Text'

import { WorkExperience as WorkExperienceModal } from '../../EditModals'

import { ReviewingProgress } from './RevieweingProgress'

import { sortExperiencesByEndDate } from 'profile/hooks'
import { useAuthenticatedUser } from 'shared/hooks'

import { CandidateContext } from 'contexts/candidate'

import { WorkExperience } from 'modules/work-experience'

interface ExperiencesReviewerProps {
  profileId: string
  isImporting?: boolean
  onClose: () => void
}

export const ExperiencesReviewer = ({
  profileId,
  isImporting = true,
  onClose,
}: ExperiencesReviewerProps) => {
  const { candidate, isLoading, loadCandidate } = useContext(CandidateContext)
  const {
    user: { isJobSeekerProfilesManager },
  } = useAuthenticatedUser()

  const [open, setOpen] = useState(true)
  const [refreshIds, setRefreshIds] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)

  const sortedExperiences = useMemo(() => {
    if (isLoading) return []

    return sortExperiencesByEndDate(candidate.experiencesHistory, {
      desc: true,
      keepEndDateUndefined: true,
    })
  }, [isLoading])

  const [experiencesIds, setExperiencesIds] = useState<Array<string>>([])

  const [currentExperience, setCurrentExperience] = useState<WorkExperience | null>(null)

  const totalSteps = experiencesIds.length
  const isLastStep = useMemo(() => currentStep + 1 === totalSteps, [currentStep, totalSteps])
  const isFirstStep = currentStep === 0
  const showSpinner = (isLoading || !currentExperience) && isFirstStep
  const lastStepLabel = isImporting ? 'Finish importing' : 'Finish'

  useEffect(() => {
    if (refreshIds && sortedExperiences.length > 0) {
      setExperiencesIds(sortedExperiences.map(({ id }) => id))
      setRefreshIds(false)
    }
  }, [refreshIds, sortedExperiences])

  useEffect(() => {
    const experienceId = experiencesIds[currentStep]
    const experience = sortedExperiences.find(({ id }) => id === experienceId)

    if (!experience) return

    setCurrentExperience(experience)
  }, [currentStep, experiencesIds, sortedExperiences])

  useEffect(() => {
    setRefreshIds(true)
    loadCandidate(isJobSeekerProfilesManager ? profileId : null)
  }, [])

  const handleStepChange = ({ activeStep }: { activeStep: number }) => setCurrentStep(activeStep)

  const handleNext = (onSubmit: () => Promise<void>) => () => {
    onSubmit()
      .then(() => {
        if (isLastStep) return handleClose()

        setCurrentStep((current) => current + 1)
      })
      .catch(() => {})
  }

  const handleClose = () => {
    setOpen(false)
    setCurrentStep(0)
    onClose()
  }

  const handleBack = () => {
    if (isFirstStep) return
    setCurrentStep((current) => current - 1)
  }

  const handleDelete = (onDelete: () => void) => {
    onDelete()
    setExperiencesIds((ids) => {
      ids.splice(currentStep, 1)
      return ids
    })
    if (isLastStep) handleClose()
  }

  return (
    <WorkExperienceModal
      fieldsToTriggerInitialWarning={['description', 'companyWebsite', 'technologies', 'location']}
      refetch
      handleClose={() => {}}
      profileId={profileId}
      isEdit
      key={currentExperience?.id}
      workExperience={currentExperience!}
      render={({ form, onDelete, onSubmit, isDeleting, isUpserting }) => (
        <Modal
          title={isImporting ? 'Import work experiences' : 'Review work experiences'}
          open={open}
          handleClose={handleClose}
          content={
            <div className="flex-grow w-full overflow-auto min-h-[80vh]">
              {showSpinner ? (
                <Spinner />
              ) : (
                <div className="flex flex-col gap-10">
                  <ReviewingProgress totalSteps={totalSteps} currentStep={currentStep + 1} />
                  <StepsWizard isLazyMount onStepChange={handleStepChange}>
                    {experiencesIds.map((id) => (
                      <div className="w-full" key={id}>
                        {form}
                      </div>
                    ))}
                  </StepsWizard>
                </div>
              )}
            </div>
          }
          footer={
            <div className="flex flex-col-reverse md:flex-row w-full md:gap-4 justify-between gap-3">
              <AlertDialog
                title="Are you sure?"
                description={<Text>This action cannot be undone.</Text>}
                confirmText="Yes, I want to delete it"
                trigger={
                  <TertiaryButton className="bg-warning-lighter text-warning-darker hover:!bg-warning-lighter w-full md:w-fit order-2 md:order-1">
                    Delete experience
                  </TertiaryButton>
                }
                onConfirmClick={() => handleDelete(onDelete)}
              />

              <div className="flex w-full md:w-fit flex-col md:flex-row gap-3 md:gap-4 md:order-2">
                <SecondaryButton
                  onClick={handleBack}
                  disabled={isFirstStep}
                  className="w-full md:w-fit order-2 md:order-1"
                >
                  Back
                </SecondaryButton>
                <PrimaryButton
                  onClick={handleNext(onSubmit)}
                  className="w-full order-1 md:order-2 md:w-fit"
                  isLoading={isLoading || isDeleting || isUpserting}
                >
                  {isLastStep ? lastStepLabel : 'Next'}
                </PrimaryButton>
              </div>
            </div>
          }
        />
      )}
    />
  )
}
