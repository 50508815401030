import { useEffect, useRef } from 'react'

import { PrimaryButton, SelectTags, Suggestion } from 'shared/components/atoms'
import { Footer, Modal } from 'shared/components/molecules'
import { Placeholder, Row } from 'shared/components/organisms'

import { Tags } from '../Tags'

import { useAuthenticatedUser, useForm } from 'shared/hooks'
import { useAssessmentTags } from 'hooks/profile'
import { useAddTags, useDeleteTag } from '../../hooks'

import { schema } from './schema'

import { AssessmentTag } from 'modules/profiles/domain'
import { cn } from 'modules/shared'
import { AssessmentTagType, tagTypePriority } from 'modules/profiles/enums'

interface Props {
  isOpen: boolean
  profileId: string
  onClose: (tags: Array<AssessmentTag>) => void
  tags?: Array<AssessmentTag>
}

export const AddAssessmentTagsModal = (props: Props) => {
  const { isOpen, profileId, onClose } = props
  const formControl = useForm({ schema, defaultValues: { assessments: props.tags || [] } })
  const { watch, setValue, getValues, reset } = formControl

  const {
    user: { email },
  } = useAuthenticatedUser()

  const { tags, isLoading, getById } = useAssessmentTags({ enabled: isOpen })
  const { addTags, isLoading: isAdding } = useAddTags(profileId)
  const { deleteTag, isDeleting } = useDeleteTag(profileId)

  const tagsOptions = tags
    .map(({ id, name, type, group }) => ({
      id,
      label: name,
      type,
      groupLabel: group.toString(),
      icon: (
        <div
          className={cn('w-3 h-3 rounded-full', {
            'bg-danger-light': type === AssessmentTagType.RedFlag,
            'bg-warning-light': type === AssessmentTagType.Warning,
            'bg-info-light': type === AssessmentTagType.Quality,
          })}
        ></div>
      ),
    }))
    .sort((a, b) => tagTypePriority[a.type] - tagTypePriority[b.type])
  const selectedTags = watch('assessments') as Array<AssessmentTag>

  const modalTitleRef = useRef(
    (() => {
      const prefix = selectedTags.length ? 'Edit' : 'Add'
      return `${prefix} assessments`
    })(),
  )

  const handleAdd = async (value: Suggestion) => {
    const { assessments } = getValues()

    const tag = getById(value.id as string)
    if (!tag) return

    tag.email = email
    setValue('assessments', [...assessments, tag])

    await addTags([tag])
  }

  const handleRemove = async (id: string) => {
    const assessments = selectedTags.filter((tag: AssessmentTag) => tag.id !== id)
    await deleteTag(id)
    setValue('assessments', assessments)
  }

  const handleClose = () => onClose(selectedTags || [])

  useEffect(() => {
    if (!isOpen) return
    reset({ assessments: props.tags || [] })
  }, [isOpen])

  return (
    <div onClick={(event) => event.stopPropagation()}>
      <Modal
        open={isOpen}
        handleClose={handleClose}
        title={modalTitleRef.current}
        contentContainerClassName="!p-10 overflow-visible"
        content={
          <form id="add-assessments-form" onSubmit={handleClose}>
            {isLoading ? (
              <Placeholder>
                <Row width="w-12/12" height="h-10" />
              </Placeholder>
            ) : (
              <SelectTags
                name="assessments"
                options={tagsOptions}
                setValue={setValue}
                watch={watch}
                onAdd={handleAdd}
                placeholder="Select assessments"
                hideTags
                containerClassName="mb-4"
                selectedOptions={[]}
              />
            )}
            <Tags tags={selectedTags} onRemove={handleRemove} />
          </form>
        }
        footer={
          <Footer>
            <PrimaryButton
              type="submit"
              form="add-assessments-form"
              isLoading={isAdding || isDeleting}
            >
              Done
            </PrimaryButton>
          </Footer>
        }
      />
    </div>
  )
}
