import { CollapsedMenu, CollapsedMenuItem } from 'shared/components/molecules'

import { AccountClaim, useAuthenticatedUser } from 'shared/hooks'
import { HomepageListing } from 'job-listing/dtos'

import { ReactComponent as DotsIcon } from 'icons/dots.svg'

interface ContextualMenuProps {
  listing: HomepageListing
}

export const ContextualMenu = ({ listing }: ContextualMenuProps) => {
  const { user } = useAuthenticatedUser()

  const menuItems: Array<CollapsedMenuItem> = [
    {
      label: 'Open on Console',
      callback: () => window.open(listing.consoleUrl, '_blank'),
      visible: user.claims.includes(AccountClaim.Console),
    },
  ].filter(({ visible }) => visible)

  if (!menuItems.length) return null

  return <CollapsedMenu triggerElement={<DotsIcon className="!w-6 !h-6" />} items={menuItems} />
}
