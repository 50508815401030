import { useAuthenticatedUser, useMonitoring, useNetwork, useToast } from 'shared/hooks'
import { useHelp } from 'modules/help'
import { Location } from 'modules/location'
import { ReferralStatus } from 'modules/referrals/enum'
import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import { nullProfile } from './null-profile'
import { Assessment, EnglishVetting } from 'modules/candidates/domain'
import { plainToInstance } from 'class-transformer'

export const useCandidate = (id: string | null | undefined) => {
  const {
    user: { isRecruiter },
  } = useAuthenticatedUser()

  const isProfileOwner = !id

  const { get } = useNetwork()
  const { toastError } = useToast()
  const { onProfileLoaded } = useHelp()

  const url = useMemo(() => {
    if (id && isRecruiter) return `referrals/${id}/profile`
    if (id) return 'candidates/' + id
    return 'profile'
  }, [id, isRecruiter])
  const { captureException } = useMonitoring()
  const {
    data: candidate,
    isFetching,
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery(
    url,
    () =>
      //@todo: refactor code (STR-1338)
      get<{
        profile: ProfileOverview
        hiringCost: number | null
        hourlyRate: number | null
        offerSent: boolean
        beginnerEnglishSpeaker: boolean
        fewYearsOfExperience: boolean
        outOfBounds: {
          byCountry: boolean
          byOpenToRole: boolean
        }
        referralStatus: ReferralStatus | null
        description: string | null
        englishVetting: EnglishVetting
        canInviteFriends: boolean
      }>(url).then(({ data }) =>
        Object.assign(data.profile, {
          yearlyRate: data.hiringCost,
          hourlyRate: data.hourlyRate,
          offerSent: data.offerSent,
          beginnerEnglishSpeaker: data.beginnerEnglishSpeaker,
          fewYearsOfExperience: data.fewYearsOfExperience,
          outOfBounds: data.outOfBounds,
          referralStatus: data.referralStatus,
          referralDescription: data.description,
          assessments: plainToInstance(Assessment, data.profile.assessments ?? []),
          englishVetting: plainToInstance(EnglishVetting, data.englishVetting),
          profile: {
            groupedExperiences: data.profile.groupedExperiences.map((groupedExperience) =>
              Object.assign(groupedExperience, {
                startDate: new Date(groupedExperience.startDate),
                endDate: new Date(groupedExperience.endDate),
                experiences: groupedExperience.experiences.map((experience) =>
                  Object.assign(experience, {
                    startDate: new Date(experience.startDate),
                    endDate: experience.endDate ? new Date(experience.endDate) : undefined,
                  }),
                ),
              }),
            ),
          },
          canInviteFriends: data.canInviteFriends,
        }),
      ),
    {
      enabled: id !== undefined,
      staleTime: 3 * 60 * 1000,
      onError: (error: RequestError) => {
        if (error.response?.status === 404) {
          toastError('Profile not found')
          return nullProfile
        }

        if (
          error.response?.status &&
          (error.response?.status >= 400 || error.response?.status <= 499)
        ) {
          toastError(
            error.response?.data.message ||
              'An error occurred while trying to fetch the profile data',
          )
          return nullProfile
        }

        toastError('An error occurred while trying to fetch the profile data')
        captureException(error)
      },
      retry: (failureCount, error: RequestError) =>
        failureCount <= 1 && error.response?.status !== 404,
    },
  )
  const isProfileLoading = isLoading || isFetching || isRefetching || isError

  const profile: ProfileOverview = useMemo(
    () =>
      candidate
        ? Object.assign(candidate, {
            fullLocation: candidate.location
              ? new Location(
                  candidate.location.city,
                  candidate.location.state,
                  candidate.location.country,
                  candidate.location.utcOffsetMinutes,
                ).label
              : 'N/A',
          })
        : nullProfile,
    [nullProfile, candidate],
  )

  useEffect(() => {
    if (profile.id && isProfileOwner) {
      onProfileLoaded(profile)
    }
  }, [profile, isProfileOwner])

  return {
    candidate: profile,
    isLoading: isProfileLoading,
    isReloading: isRefetching,
    readOnly: !id,
    refetch,
    isFirstLoading: isLoading && !isRefetching,
  }
}
