import { useAuthenticatedUser } from 'shared/hooks'
import { useHelp } from 'modules/help'

export const useSession = () => {
  const { signOut } = useAuthenticatedUser()
  const { shutdownChat } = useHelp()

  const endSession = (redirectUrl = '/login') =>
    signOut().then(() => {
      shutdownChat()
      window.location.assign(redirectUrl)
    })

  return { endSession }
}
