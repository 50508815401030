import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as SettingsIcon } from 'icons/settings-04.svg'

import { AccountClaim, useAuthenticatedUser } from 'shared/hooks'

import { ListingInfo } from 'job-listing/domains'

import { JobListingSettingsModal } from './components'
import { CollapsedMenu, CollapsedMenuItem } from 'shared/components/molecules'
import { ButtonType, TertiaryButton, Tooltip } from 'shared/components/atoms'

interface ListingManagementMenuProps {
  listing: ListingInfo
  readOnly?: boolean
}

export const ListingManagementMenu = ({ listing, readOnly }: ListingManagementMenuProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const {
    user: { claims },
  } = useAuthenticatedUser()
  const navigate = useNavigate()

  const handleOpenListingSettings = () => setIsModalOpen(true)
  const handleCloseListingSettings = () => setIsModalOpen(false)

  const handleOpenScreenings = () => navigate(`/listings/${listing.id}/screenings`)

  const menuItems = useMemo<Array<CollapsedMenuItem>>(
    () =>
      [
        {
          label: 'Job listing settings',
          callback: handleOpenListingSettings,
          visible: true,
        },
        {
          label: 'Application forms',
          callback: handleOpenScreenings,
          visible: claims.includes(AccountClaim.ScreeningManagement),
        },
        {
          label: 'Open on Console',
          callback: () => window.open(listing.consoleUrl, '_blank'),
          visible: claims.includes(AccountClaim.Console),
        },
      ].filter(({ visible }) => visible),
    [listing.status],
  )
  const singleOption = menuItems.length === 1 && menuItems[0]
  const icon = <SettingsIcon className="w-6 h-6 stroke-neutral-darker" />

  if (readOnly || !menuItems.length) return null

  return (
    <>
      {singleOption ? (
        <Tooltip content={singleOption.label}>
          <TertiaryButton
            icon={icon}
            size="lg"
            onClick={handleOpenListingSettings}
            aria-label={singleOption.label}
            tabIndex={-1}
          />
        </Tooltip>
      ) : (
        <CollapsedMenu
          buttonType={ButtonType.Tertiary}
          triggerElement={icon}
          items={menuItems}
          triggerElementSize="lg"
          aria-label="Listing settings"
        />
      )}
      <JobListingSettingsModal
        isOpen={isModalOpen}
        jobListing={listing}
        onClose={handleCloseListingSettings}
      />
    </>
  )
}
