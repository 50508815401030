import { useAuthenticatedUser, useMonitoring, useNetwork, useToast } from 'shared/hooks'

import { useMutation } from 'react-query'

export const useDemoMode = () => {
  const { user } = useAuthenticatedUser()
  const { toastError } = useToast()
  const { patch } = useNetwork()
  const { captureException } = useMonitoring()

  const active = user.demoModeActive

  const { mutateAsync: toggle, isLoading } = useMutation(
    () => patch('accounts/demos', { enable: !user.demoModeActive }),
    {
      onError: (error: RequestError) => {
        toastError('Could not enable demo mode. Please tray again.')
        captureException(error)
      },
    },
  )

  return { active, toggle, isToggling: isLoading }
}
