import { KeyboardEvent } from 'react'
import { object, string } from 'yup'

import { Autocomplete, Suggestion } from 'shared/components/atoms'
import { useCompanies } from 'company/exports'
import { useForm } from 'shared/hooks'

const schema = object().shape({
  query: string(),
})

interface CompaniesSelectorProps {
  onSelect: (selected: Suggestion) => Promise<void>
  onBlur: () => void
}
export const CompaniesSelector = ({ onSelect, onBlur }: CompaniesSelectorProps) => {
  const { approvedCompanyOptions } = useCompanies()
  const { register, setValue } = useForm({
    schema,
    defaultValues: { query: '', companyId: null },
  })

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key == 'Escape') onBlur()
  }

  return (
    <div
      className="
        absolute left-0 top-full mt-2 z-30 py-2
        min-w-[270px] bg-white rounded-lg
      "
    >
      <Autocomplete
        autoFocus
        suggestions={approvedCompanyOptions}
        register={register}
        setValue={setValue}
        name="companyId"
        handleAdd={onSelect}
        className="m-2 w-full"
        suggestionsClassName="pb-2 rounded-b-lg"
        allowCreation={false}
        onBlur={onBlur}
        onKeyDown={handleKeyDown}
      />
    </div>
  )
}
