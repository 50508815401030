export enum DisqualifyCandidateReviewReasons {
  LackOfSeniority = 'Lack of seniority',
  InsufficientMarketFit = 'Insufficient industry/market fit',
  LimitedWorkExperience = 'Limited work experience',
  SkillsMismatch = 'Skills/experience mismatch',
  UnclearProfile = 'Unclear or incomplete profile',
  Other = 'Other',
}

export enum DisqualifyIntroCallReasons {
  OtherBetterFits = 'Other candidates better fit the role',
  CulturalMismatch = 'Cultural mismatch',
  InsufficientSeniority = 'Insufficient seniority',
  CareerFit = 'Career fit',
  SuperficialAnswers = 'Vague or superficial answers',
  InsufficientExperience = 'Insufficient skills/experience',
  InsufficientIndustryExperience = 'Insufficient industry-related experiences',
  InsufficientCommunication = 'Insufficient communication abilities',
  Other = 'Other',
}
