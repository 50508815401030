import { TrackingEvent } from '../tracking-event'

export class JobOfferSent implements TrackingEvent {
  name = 'Job offer sent'

  constructor(private readonly matchingId: string) {}

  get payload() {
    return { matchingId: this.matchingId }
  }
}
