import { useAuthenticatedUser } from 'shared/hooks'
import { Outlet, useNavigate } from 'react-router-dom'

export const EmployerRoutes = () => {
  const navigate = useNavigate()
  const {
    user: { accountId, isEmployer, company },
    isLoaded,
  } = useAuthenticatedUser()

  if (!isLoaded) return null
  if (!company?.approved) navigate('/welcome-not-ready')
  if (isEmployer) return <Outlet />
  if (accountId) navigate('/')

  return null
}
