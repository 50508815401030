import { useMutation, useQueryClient } from 'react-query'

import { useMonitoring, useNetwork, useToast } from 'shared/hooks'
import { SortedExperiences, Stack } from 'pages/JobSeekerProfile/types'
import { ExperienceRange } from 'modules/candidates/experience-range/experience-range'
import { WorkExperience } from 'modules/work-experience'

import { useOrganization } from './useOrganization'

export const sortExperiencesByEndDate = (
  experiences: Array<WorkExperience>,
  { desc, keepEndDateUndefined }: { desc: boolean; keepEndDateUndefined: boolean } = {
    desc: true,
    keepEndDateUndefined: false,
  },
) => {
  const now = Date.now()

  return [...experiences]
    .sort((a, b) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function dateInterval(dateA: any, dateB: any) {
        const timeA = dateA ? new Date(dateA).getTime() : now
        const timeB = dateB ? new Date(dateB).getTime() : now

        return Math.round(timeA - timeB)
      }

      const referenceOrder = desc ? -1 : 1
      const intervalByEndDate = dateInterval(a.endDate, b.endDate)

      if (!intervalByEndDate) {
        return dateInterval(a.startDate, b.startDate) * referenceOrder
      }

      return intervalByEndDate * referenceOrder
    })
    .map((experience): SortedExperiences => {
      const endDateFallback = keepEndDateUndefined ? undefined : new Date()

      return {
        ...experience,
        startDate: new Date(experience.startDate),
        endDate: experience.endDate ? new Date(experience.endDate) : endDateFallback,
      }
    })
}

const calculateDateDifference = (startDate: Date, endDate: Date) => {
  return endDate.getTime() - startDate.getTime()
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const buildPayload = (values: { [x: string]: any }) => {
  return {
    organizationId: values.companyId,
    title: values.title,
    stacks: values.technologies.map((tech: Stack) => tech.id),
    location: values.location || null,
    startDate: values.startDate,
    endDate: values.endDate,
    description: values.description || null,
    numberOfManagedPeople: values.isManagement ? Number(values.numberOfManagedPeople) : 0,
    percentageOfWorkManagingPeople: values.isManagement ? 100 - values.percentageOfWorkBuilding : 0,
    isRemote: values.isRemote,
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const buildNewOrganizationPayload = (values: { [x: string]: any }) => {
  return {
    id: values.companyId || null,
    name: values.companyName,
    website: values.companyWebsite || null,
    publish: values.publishOrganization,
  }
}

export const calculateTotalWorkExperience = (experiences: Array<WorkExperience>) => {
  let interval = 0,
    begin!: Date,
    end!: Date
  const sortedExperiences = sortExperiencesByEndDate(experiences)

  sortedExperiences.forEach((experience) => {
    let difference = 0

    if ((!end && !begin) || begin > experience.endDate!) {
      begin = experience.startDate
      end = experience.endDate!

      difference = calculateDateDifference(begin, end)
    } else if (begin <= experience.endDate! && begin > experience.startDate) {
      difference = calculateDateDifference(experience.startDate, begin)
      begin = experience.startDate
    }

    interval += difference
  })

  const startDate = new Date(0)
  const endDate = new Date(interval)

  return { duration: new ExperienceRange(startDate, endDate), begin, end }
}

export const useWorkExperience = ({
  profileId,
  refetch,
  readOnly = false,
}: {
  profileId?: string
  readOnly?: boolean
  refetch?: boolean
}) => {
  const client = useQueryClient()
  const { post, put, delete: deleteReq } = useNetwork()
  const { upsertOrganization } = useOrganization(profileId, !readOnly)
  const { toastError } = useToast()
  const { captureException } = useMonitoring()

  const { mutate: deleteWorkExperience, isLoading: isDeleting } = useMutation(
    (educationId: string) => deleteReq(`profile/${profileId}/work-experience/${educationId}`),
    {
      onSuccess: () => {
        if (!refetch) return
        client.invalidateQueries('profile')
        client.invalidateQueries(`candidates/${profileId}`)
      },
      onError: (error: RequestError) => {
        toastError(
          `Error when deleting the work experience record: ${error.response?.data.message ?? 'Unknown error'}`,
        )
        captureException(error)
      },
    },
  )

  const { mutateAsync: upsertWorkExperience, isLoading: isUpserting } = useMutation(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (workExperience: { [x: string]: any }) => {
      const { data: newOrganization } = await upsertOrganization(
        buildNewOrganizationPayload(workExperience),
      )
      if (!workExperience.companyId) workExperience.companyId = newOrganization.id

      if (workExperience.id) return update(workExperience)
      return create(workExperience)
    },
    {
      onSuccess: () => {
        if (!refetch) return
        client.invalidateQueries('profile')
        client.invalidateQueries(`candidates/${profileId}`)
      },
      onError: (error: RequestError) => {
        toastError(
          `Error when upserting the work experience: ${error.response?.data.message ?? 'Unknown error'}`,
        )
        captureException(error)
      },
    },
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const create = (workExperience: { [x: string]: any }) =>
    post(`profile/${profileId}/work-experience`, buildPayload(workExperience))

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const update = (workExperience: { [x: string]: any }) =>
    put(`profile/${profileId}/work-experience/${workExperience.id}`, buildPayload(workExperience))

  const parseWorkExperienceToFormSchema = (workExperience: WorkExperience) => {
    const startDate = workExperience.startDate as Date
    const endDate = workExperience.endDate as Date | null

    return Object.assign({}, workExperience, {
      companyId: workExperience.experienceOrganization.id,
      companyName: workExperience.experienceOrganization.name,
      companyWebsite: workExperience.experienceOrganization.website || null,
      isTechnical: !!workExperience.stacks.length,
      technologies: workExperience.stacks,
      isCurrently: !endDate,
      startDate: startDate.toISOString().substring(0, 10),
      endDate: endDate ? endDate.toISOString().substring(0, 10) : null,
      isManagement: Boolean(workExperience.numberOfManagedPeople),
      numberOfManagedPeople: workExperience.numberOfManagedPeople,
      percentageOfWorkBuilding: 100 - workExperience.percentageOfWorkManagingPeople,
      isRemote: workExperience.isRemote,
    })
  }

  return {
    upsertWorkExperience,
    deleteWorkExperience,
    parseWorkExperienceToFormSchema,
    calculateTotalWorkExperience,
    isUpserting,
    isDeleting,
  }
}
