import { useMutation } from 'react-query'

import { useNetwork, useToast, useMonitoring } from 'shared/hooks'

import { MatchingFlagType } from 'matching/enums'

interface Props {
  listingId: string
  matchingId: string
}

export const useAddFlags = ({ listingId, matchingId }: Props) => {
  const { post } = useNetwork()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()

  const { mutateAsync: addFlags, isLoading } = useMutation(
    (flags: Array<MatchingFlagType>) => {
      return post(`listings/${listingId}/matchings/${matchingId}/matching-flags`, { flags })
    },
    {
      onError: (error: RequestError) => {
        toastError(
          `Failed to add double checking flags: ${error.response?.data.message ?? 'Unknown error'}`,
        )
        captureException(error)
      },
    },
  )

  return { addFlags, isLoading }
}
