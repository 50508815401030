import { Expose, Type } from 'class-transformer'
import { Location } from 'modules/location'
import { WorkExperience } from 'modules/work-experience'
import { MatchingStatus } from 'matching/enums'
import { Stack } from 'modules/stack'
import { RejectionRequest } from 'matching/dtos'

@Expose()
class Profile {
  id: string
  firstName: string
  lastName: string
  photoUrl: string
  yearlyRate: string
  noticePeriodDays: number

  @Type(() => Location)
  location: Location

  @Type(() => WorkExperience)
  experiencesHistory: Array<WorkExperience>

  @Type(() => WorkExperience)
  lastWorkExperience: WorkExperience

  get fullName() {
    return [this.firstName, this.lastName].filter((item) => item).join(' ')
  }

  get blurName() {
    return `${this.firstName} ${this.lastName[0]}.`
  }
}

@Expose()
class RejectionFeedback {
  reasons: Array<string>
  otherReason?: string
}

@Expose()
export class Matching {
  id: string

  status: MatchingStatus

  bookmarked: boolean

  @Type(() => Profile)
  profile: Profile

  stacks: Array<string>

  hiringCost?: number

  compensation?: number

  fee?: number

  notes: string | null

  @Type(() => RejectionFeedback)
  rejectionFeedback: Array<RejectionFeedback>

  updatedAt: string

  firstSharedAt: string | null

  briefing: string

  highlightedSkills: Optional<Array<Stack>>

  isUnavailable: boolean
  startDate: Nullable<Date>
  finalInterviewFeedback: string
  hasActiveJobOffer: boolean

  introCallRequested: boolean
  finalInterviewRequested: boolean

  get rejectionNotes() {
    if (this.status === 'rejected') return this.notes

    return null
  }

  get isShared() {
    return Boolean(this.firstSharedAt)
  }

  get canBeDeleted() {
    const statusesAllowedForDeletion = [
      MatchingStatus.Screening,
      MatchingStatus.ScreeningApproved,
      MatchingStatus.Saved,
      MatchingStatus.Shortlisted,
      MatchingStatus.NotAFit,
      MatchingStatus.Matched,
    ]

    return statusesAllowedForDeletion.includes(this.status)
  }

  get isMatched() {
    return this.status === MatchingStatus.Matched
  }

  get isIntroCallRequested() {
    return this.status === MatchingStatus.IntroCallRequested
  }

  get isFinalInterviewRequested() {
    return this.status === MatchingStatus.FinalInterviewRequested
  }

  get isDisqualified() {
    return this.status === MatchingStatus.Rejected
  }

  get hasBriefing() {
    return Boolean(this.briefing)
  }

  get pendingReview() {
    return this.status === MatchingStatus.Matched
  }

  transitToMatched() {
    this.status = MatchingStatus.Matched
  }

  transitToRejected() {
    this.status = MatchingStatus.Rejected
  }

  transitToIntroCallRequested() {
    this.status = MatchingStatus.IntroCallRequested
    this.introCallRequested = true
  }

  transitToFinalInterviewRequested(feedback: string) {
    this.status = MatchingStatus.FinalInterviewRequested
    this.finalInterviewRequested = true
    this.finalInterviewFeedback = feedback
  }

  transitToDisqualified(rejection: RejectionRequest) {
    this.status = MatchingStatus.Rejected
    this.rejectionFeedback = rejection.rejectionFeedback
    this.notes = rejection.notes
  }

  reconsider() {
    this.rejectionFeedback = []
    this.notes = null

    if (this.finalInterviewRequested) {
      this.status = MatchingStatus.FinalInterviewRequested
      return
    }

    if (this.introCallRequested) {
      this.status = MatchingStatus.IntroCallRequested
      return
    }

    this.status = MatchingStatus.Matched
  }

  toggleSaved() {
    this.bookmarked = !this.bookmarked
  }
}
