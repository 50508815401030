import { Checkbox, Label, Text } from 'shared/components/atoms'
import { IconBanner } from 'shared/components/molecules'

import { ReactComponent as WarningIcon } from 'icons/x-square.svg'
import { JobDescription, Preferences } from 'job-opportunity/domain'
import { useForm } from 'shared/hooks'
import { boolean, object } from 'yup'
import { useEffect } from 'react'

interface B1ApplicantMisalignmentProps {
  showMisalignments: boolean
  jobDescription: Pick<JobDescription, 'misalignedPreferences' | 'minimumEnglishLevel'>
  onConfirm: (value: boolean) => void
}

export const B1ApplicantMisalignment = ({
  showMisalignments,
  jobDescription,
  onConfirm,
}: B1ApplicantMisalignmentProps) => {
  const isMisaligned = jobDescription.misalignedPreferences.includes(
    Preferences.EnglishLevelB1Applicant,
  )

  const { register, watch } = useForm({
    schema: object().shape({ englishLevelAcknowledged: boolean() }),
    defaultValues: { englishLevelAcknowledged: !isMisaligned },
  })

  const englishLevelAcknowledged = watch('englishLevelAcknowledged') as boolean

  useEffect(() => {
    onConfirm(englishLevelAcknowledged || !showMisalignments)
  }, [englishLevelAcknowledged, showMisalignments])

  if (!showMisalignments || !isMisaligned) return null

  return (
    <IconBanner
      icon={<WarningIcon className="w-6 h-6 stroke-warning-medium shrink-0" />}
      bgColor="bg-warning-lighter"
      className="mt-4"
    >
      <div className="flex flex-col gap-4">
        <Text weight="font-medium">
          Your English level doesn't meet the requirements for this role
        </Text>
        <Text>
          Your English level has been evaluated as B1, which is below the minimum requirement of{' '}
          {jobDescription.minimumEnglishLevel} for this position. While you can apply, please note
          that you are not likely to be considered for this position.
        </Text>
        <Label
          htmlFor="englishLevelAcknowledged"
          weight="font-normal"
          className="flex items-center py-4 mb-0"
        >
          <Checkbox
            register={register}
            id="englishLevelAcknowledged"
            name="englishLevelAcknowledged"
          />
          I acknowledge that and wish to apply anyway
        </Label>
      </div>
    </IconBanner>
  )
}
