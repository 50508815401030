import { useNavigate } from 'react-router-dom'

import { EmployerOwnerOnly, TertiaryButton } from 'shared/components/atoms'
import { ReactComponent as EditIcon } from 'icons/edit.svg'
import { useAuthenticatedUser } from 'shared/hooks'

interface EditListingButtonProps {
  listingId: string
}

export const EditListingButton = ({ listingId }: EditListingButtonProps) => {
  const { user } = useAuthenticatedUser()

  const navigate = useNavigate()
  const handleEdit = () => {
    if (user.links?.createJobListing) {
      const url = `${user.links.createJobListing}/${listingId}`
      window.open(url, '_blank')
      return
    }

    navigate(`/listings/create/${listingId}`)
  }

  return (
    <EmployerOwnerOnly>
      <TertiaryButton
        size="md"
        className="m-0"
        icon={<EditIcon className="h-[16px] w-[16px] stroke-neutral-darkest stroke-[1.5px]" />}
        onClick={handleEdit}
        isIconBefore
        aria-label="Edit"
      />
    </EmployerOwnerOnly>
  )
}
