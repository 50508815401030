import { useState } from 'react'

import { ClaimProtected, Text } from 'shared/components/atoms'

import { AddProficiencyTagsModal } from '../AddProficiencyTagsModal'
import { Tags } from '../Tags'

import { AccountClaim, useAuthenticatedUser } from 'shared/hooks'
import { useDeleteProficiency } from '../../hooks'

import { ProficiencyTag } from 'modules/profiles/domain'
import { cn } from 'modules/shared'

interface Props {
  profileId: string
  proficiencies: Array<ProficiencyTag>
  skills?: Array<{ id: number; label: string; highlight: boolean }>
  hideTitle?: boolean
  containerClassName?: string
  onTagsAdded?: (tags: Array<ProficiencyTag>) => void
  onTagsRemoved?: (id: number) => void
}
export const ProficiencyTagsSection = ({
  profileId,
  proficiencies,
  hideTitle,
  skills,
  containerClassName,
  onTagsAdded,
  onTagsRemoved,
}: Props) => {
  const {
    user: { claims },
  } = useAuthenticatedUser()

  const [addProficiencyTagsModalOpen, setAddProficiencyTagsModalOpen] = useState(false)

  const { deleteProficiency } = useDeleteProficiency(profileId)

  const handleRemove = (id: number) => {
    deleteProficiency(id)
    if (onTagsRemoved) onTagsRemoved(id)
  }
  const handleAdd = () => setAddProficiencyTagsModalOpen(true)
  const handleClose = (tags: Array<ProficiencyTag>) => {
    setAddProficiencyTagsModalOpen(false)
    if (onTagsAdded) onTagsAdded(tags)
  }

  return (
    <ClaimProtected require={AccountClaim.ViewProfileProficiencies}>
      <div className="print:hidden">
        <div
          className={cn('flex flex-col md:flex-row gap-2 md:gap-6', containerClassName)}
          onClick={(event) => event.stopPropagation()}
        >
          {!hideTitle && (
            <div className="md:h-12 flex items-center md:py-[10px] shrink-0 w-[100px]">
              <Text size="text-xs" className="text-neutral-dark">
                Proficiency
              </Text>
            </div>
          )}
          <div className="w-full p-2 hover:bg-neutral-lightest hover:bg-opacity-50 transition-all group">
            <Tags
              proficiencies={proficiencies}
              skills={skills}
              editOnHover
              onRemove={handleRemove}
              onAdd={handleAdd}
              readOnly={!claims.includes(AccountClaim.ManageProfileProficiencies)}
            />
          </div>
        </div>
        <AddProficiencyTagsModal
          isOpen={addProficiencyTagsModalOpen}
          proficiencies={proficiencies}
          profileId={profileId}
          onClose={handleClose}
        />
      </div>
    </ClaimProtected>
  )
}
