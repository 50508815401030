import { Agreements } from 'account/types'
import { useMonitoring, useNetwork } from 'shared/hooks'
import { useQuery } from 'react-query'
import { SupplementalTermsFeature } from 'modules/supplemental-terms/enums'

export const useSupplementalTerms = (feature: SupplementalTermsFeature) => {
  const { get } = useNetwork()
  const { captureException } = useMonitoring()

  const {
    data: pendingSupplementalTerms = null,
    isError: error,
    isLoading,
  } = useQuery(
    `supplemental-terms/${feature}/pending`,
    () =>
      get<Nullable<Agreements>>(`agreements/supplemental-terms/${feature}/pending`).then(
        ({ data }) => data,
      ),
    {
      retry: 5,
      onError: (error: RequestError) => {
        captureException(error)
      },
    },
  )

  const { data: lastAcceptedSupplementalTerms = null } = useQuery(
    `supplemental-terms/${feature}/latest-accepted`,
    () =>
      get<Nullable<Agreements>>(`agreements/supplemental-terms/${feature}/latest-accepted`).then(
        ({ data }) => data,
      ),
    {
      retry: 5,
      onError: (error: RequestError) => {
        captureException(error)
      },
    },
  )

  return {
    pendingSupplementalTerms,
    lastAcceptedSupplementalTerms,
    error,
    isLoading,
  }
}
