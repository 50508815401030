import { useEffect } from 'react'
import { number, object, string } from 'yup'

import { Modal } from 'shared/components/molecules'
import { MatchingSimulation, MatchBriefing } from 'matching/components'

import { SimulationFields } from './components/SimulationFields'
import { LastEditor } from './components/LastEditor'
import { Footer } from './components/Footer'

import { useForm, useDebounce } from 'shared/hooks'
import {
  useMatchingSettings,
  useUpdateMatchingSettings,
  useMatchingSimulation,
} from 'hooks/matchings'

import { PaymentModel } from 'job-listing/enums'
import { MatchingSettingsFormSchema } from 'matching/domain'
import { ProfileHeading } from 'components/organisms/Profile/Heading'
import { MatchingStatus } from 'matching/enums'

const schema = object().shape({
  compensation: number().when('paymentModel', {
    is: PaymentModel.Monthly,
    then: number()
      .required()
      .min(1000, "The candidate's expected pay rate can't be less than $1000")
      .max(30000, "The candidate's expected pay rate can't be greater than $30000"),
    otherwise: number()
      .required()
      .min(1, "The candidate's expected pay rate can't be less than $1")
      .max(200, "The candidate's expected pay rate can't be greater than $200"),
  }),
  fee: number().required().min(1, "The fee can't be less than $1"),
  paymentModel: string(),
  briefing: string().max(10000),
})

export interface UpdateSettingsModalProps {
  title: string
  matchingId: string
  matchingStatus: MatchingStatus
  firstSharedAt: string | null
  jobListingId: string
  profileId: string
  initialCompensation: number
  initialFee: number
  isHourlyRatesEnabled: boolean
  enableBriefing?: boolean
  onClose: () => void
  updateValues?: (compensation: number, fee: number) => void
}

export const MatchingUpdateSettingsModal = ({
  title,
  matchingId,
  matchingStatus,
  firstSharedAt,
  jobListingId,
  profileId,
  initialCompensation,
  initialFee,
  isHourlyRatesEnabled,
  enableBriefing = true,
  onClose,
  updateValues,
}: UpdateSettingsModalProps) => {
  const { matchingSettings, isLoading: isSettingsLoading } = useMatchingSettings({
    listingId: jobListingId,
    matchingId: matchingId,
  })

  const formControl = useForm({
    mode: 'onChange',
    schema,
    defaultValues: {
      compensation: initialCompensation,
      fee: initialFee,
      highlightedSkills: [],
      paymentModel: isHourlyRatesEnabled ? PaymentModel.Hourly : PaymentModel.Monthly,
    },
  })

  const { watch, handleSubmit, getValues, reset, isValid } = formControl

  const compensation = Number(watch('compensation'))
  const fee = Number(watch('fee'))
  const paymentModel = watch('paymentModel')

  const { updateMatchingSettings, isUpdatingSettings } = useUpdateMatchingSettings({
    listingId: jobListingId,
    matchingId: matchingId,
    profileId,
  })

  const { profile } = matchingSettings
  const { simulation, isLoading } = useMatchingSimulation(
    profileId,
    jobListingId,
    compensation,
    isValid,
    fee,
  )

  const { debouncedValue: showRateWarning } = useDebounce(
    compensation < matchingSettings.profile.rate.min && !isSettingsLoading,
    1000,
  )

  const handleFormSubmission = handleSubmit(() => {
    const settings = getValues() as MatchingSettingsFormSchema
    if (updateValues) updateValues(compensation, fee)
    updateMatchingSettings(settings).then(onClose)
  })

  useEffect(() => {
    reset({
      compensation: matchingSettings?.compensation || initialCompensation,
      fee: matchingSettings?.fee || initialFee,
      paymentModel: isHourlyRatesEnabled ? PaymentModel.Hourly : PaymentModel.Monthly,
      briefing: matchingSettings?.briefing,
      highlightedSkills: matchingSettings?.highlightedSkills ?? [],
    })
  }, [matchingSettings, isHourlyRatesEnabled])

  const formContent = (
    <form className="flex flex-col gap-6" onSubmit={handleFormSubmission} id="settings-form">
      <ProfileHeading profile={profile} firstSharedAt={firstSharedAt} />
      <LastEditor matchingSettings={matchingSettings} />

      <div className="flex flex-col gap-12">
        <SimulationFields
          formControl={formControl}
          showRateWarning={showRateWarning}
          paymentModel={paymentModel}
        />
        <MatchingSimulation
          readOnly
          client={matchingSettings.client.name}
          isMissingParams={false}
          minCompensation={matchingSettings.profile.rate.min}
          maxCompensation={matchingSettings.profile.rate.max}
          simulation={simulation}
          isValid={isValid}
          paymentModel={paymentModel}
        />
      </div>

      {enableBriefing && (
        <MatchBriefing
          candidateFirstName={profile.firstName}
          formControl={formControl}
          showEditButton
          showOptionalLabel
        />
      )}
    </form>
  )

  return (
    <Modal
      open
      handleClose={onClose}
      title={title}
      content={formContent}
      footer={
        <Footer
          matchingId={matchingId}
          status={matchingStatus}
          jobListingId={jobListingId}
          isLoading={isLoading || isUpdatingSettings || isSettingsLoading}
          onClose={onClose}
        />
      }
    />
  )
}
