import { Text } from 'components/Text'
import { Title } from 'shared/components/atoms'
import { useAuthenticatedUser } from 'shared/hooks'
import { useHelp } from 'modules/help'

export const AccountTypeFooter = () => {
  const { shutdownChat } = useHelp()
  const { user, signOut } = useAuthenticatedUser()

  const handleSignOut = () => {
    signOut().then(() => {
      shutdownChat()
      window.location.assign('/login')
    })
  }

  return (
    <div className="flex flex-col gap-2 items-center">
      <Text size="small-body" weight="font-medium" className="text-neutral-dark">
        You are logged in as <span className="text-neutral-darkest">{user.email}</span>
      </Text>
      <Title
        size="micro-heading"
        className="!text-info-medium cursor-pointer"
        onClick={handleSignOut}
      >
        Change account
      </Title>
    </div>
  )
}
