import { Link } from 'react-router-dom'

import { Title } from 'shared/components/atoms'
import { StriderUsersOnly } from 'components/Access/StriderUsersOnly'
import { MatchingPhotos } from 'components/molecules'
import { ListingStatusBadge, TalentReviewBadge } from 'job-listing/exports'
import { ListingStatus } from 'job-listing/enums'
import { HomepageListing } from 'job-listing/dtos'

import { RateLabel } from './RateLabel'
import { ExperienceLabel } from './ExperienceLabel'
import { OpeningsLabel } from './OpeningsLabel'
import { ContextualMenu } from './ContextualMenu'
import { OwnerLabel } from './OwnerLabel'
import { ContractLabel } from './ContractLabel'
import { cn } from 'modules/shared'

interface ListingCardProps {
  listing: HomepageListing
}

export const ListingCard = ({ listing }: ListingCardProps) => {
  const color = listing.status === ListingStatus.Closed ? 'neutral-dark' : 'neutral-darkest'

  const buildListingRoute = () => {
    return `/listings/${listing.id}`
  }

  return (
    <>
      <Link
        to={buildListingRoute()}
        className={cn(
          'flex justify-between',
          'p-6 md:p-8 w-full bg-white',
          'rounded-lg border border-neutral-light',
          'hover:border-neutral-darkest hover:border-2 hover:p-[23px] md:hover:p-[31px]',
        )}
      >
        <div className="flex flex-col">
          <Title
            size="heading"
            color={
              listing.status === ListingStatus.Closed ? 'text-neutral-dark' : 'text-neutral-darkest'
            }
            className="line-clamp-1 text-ellipsis font-medium mb-4"
          >
            {listing.title}
          </Title>

          <div className="flex items-center gap-x-10 gap-y-4 mb-7 flex-wrap">
            <RateLabel label={listing.budgetLabel} color={color} />
            <ExperienceLabel label={listing.experienceRangeLabel} color={color} />
            <ContractLabel label={listing.contractDetailsLabel} color={color} />
            <OpeningsLabel value={listing.numberOfOpenings} color={color} />
          </div>

          <div className="flex items-center gap-x-4 gap-y-7  md:gap-8 flex-wrap">
            <div className="flex gap-2">
              <ListingStatusBadge status={listing.status} />
              <TalentReviewBadge isTalentReview={listing.isTalentReview} />
            </div>

            <MatchingPhotos
              listingId={listing.id}
              matchings={listing.matchings}
              matchingsCount={listing.matchingsCount}
              listingStatus={listing.status}
            />
            <StriderUsersOnly>
              <OwnerLabel company={listing.company} />
            </StriderUsersOnly>
          </div>
        </div>

        <ContextualMenu listing={listing} />
      </Link>
    </>
  )
}
