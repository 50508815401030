import { useAuthenticatedUser, useNetwork } from 'shared/hooks'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { Tasks } from 'modules/candidates/enums'

export const useTasks = (profileId: string) => {
  const { get, post } = useNetwork()
  const {
    user: { isSeeker },
  } = useAuthenticatedUser()
  const client = useQueryClient()

  const { data: tasks = [] } = useQuery(
    'tasks',
    async () =>
      await get<Array<{ type: Tasks }>>('tasks').then(({ data }) => data.map((task) => task.type)),
    { retry: 5, staleTime: 3 * 60 * 1000, enabled: isSeeker },
  )

  const { mutateAsync: acknowledgeUpdates } = useMutation(
    () => post(`/profile/${profileId}/updates-acknowledgment`),
    { retry: 3, onSuccess: () => client.invalidateQueries('tasks') },
  )

  return { tasks, acknowledgeUpdates }
}
