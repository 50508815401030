import {
  Autocomplete,
  FieldWrapper,
  Suggestion,
  Label,
  Counter,
  FieldError,
} from 'shared/components/atoms'

import { Contract, JobTitle, RequiredSkills, TotalExperience } from 'job-listing/components'

import { Budget, JobDescription, PaymentModel } from './components'

import { useProfile } from 'hooks/profile'
import { FormControl } from 'shared/hooks'

import { FormListing } from 'job-listing/domains'

export const JobSpecifications = ({
  formControl,
  autoFocusFirstInput,
  initialListing,
}: {
  autoFocusFirstInput: boolean
  formControl: FormControl
  initialListing?: FormListing
}) => {
  const { register, setValue, getValues, watch, getError } = formControl
  const { profileRolesWithGroups, getRoleType } = useProfile()

  const handleRoleChange = async (value: Suggestion) => {
    setValue('roleId', value.id || null, { shouldDirty: true })
    setValue('roleLabel', value.label)
    setValue('roleType', value.id ? getRoleType(Number(value.id)) : 'other')
  }

  return (
    <div className="w-full">
      <FieldWrapper className="!mt-10">
        <JobTitle
          name="jobTitle"
          formControl={{ register } as FormControl}
          autoFocus={autoFocusFirstInput}
        />
      </FieldWrapper>

      <FieldWrapper className="!mt-3">
        <Label htmlFor="roleLabel" size="text-sm">
          Role
        </Label>
        <Autocomplete
          id="roleLabel"
          name="roleLabel"
          register={register}
          setValue={setValue}
          suggestions={profileRolesWithGroups}
          handleAdd={handleRoleChange}
          placeholder="e.g. Back-end Developer"
          clearInputValue={false}
          allowCreation={true}
          showDescription={false}
          matchDescription
        />
        <FieldError message={getError('roleLabel')} />
      </FieldWrapper>

      <PaymentModel register={register} watch={watch} initialListing={initialListing} />

      <div className="flex flex-col sm:flex-row gap-4 lg:gap-24 mt-10">
        <Budget register={register} watch={watch} setValue={setValue} getError={getError} />
        <FieldWrapper
          className="lg:mr-[72px] !mt-0 max-w-[190px]"
          aria-labelledby="numberOfOpenings"
        >
          <Label id="numberOfOpenings" htmlFor="numberOfOpenings" size="text-sm">
            Number of openings
          </Label>
          <Counter
            name="numberOfOpenings"
            register={register}
            getValues={getValues}
            setValue={setValue}
          />
        </FieldWrapper>
      </div>

      <FieldWrapper className="!mt-3">
        <TotalExperience
          name="experienceRange"
          formControl={formControl}
          error={getError('experienceRange')}
          isRangedSlider
        />
      </FieldWrapper>

      <Contract
        formControl={{ register, watch, setValue } as FormControl}
        name="engagementType"
        contractDurationName="contractLength"
        workingHoursPerWeekName="freelancingWorkingHoursPerWeek"
        className="mt-9"
      />

      <FieldWrapper className="!mt-16">
        <RequiredSkills name="stacks" formControl={formControl} />
      </FieldWrapper>

      <JobDescription register={register} />
    </div>
  )
}
