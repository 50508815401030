import * as RadixTabs from '@radix-ui/react-tabs'
import { cn } from 'modules/shared'

interface TabsTriggerProps {
  children: React.ReactNode
  value: string
  onClick?: () => void
  index: number
  active: boolean
  disabled?: boolean
  classNames?: string
}

export const TabTrigger = ({
  onClick,
  children,
  value,
  index,
  active,
  disabled,
  classNames,
}: TabsTriggerProps) => {
  return (
    <RadixTabs.Trigger
      data-state={active ? 'active' : 'inactive'}
      value={value}
      onMouseDown={onClick}
      className={cn(
        'flex gap-3 items-center !py-3 mx-6',
        'border-b-2 border-transparent',
        'text-sm',
        {
          'ml-0': index === 0,
          'opacity-50 hover:!text-neutral-dark': disabled,
        },
        classNames,
      )}
      disabled={disabled}
    >
      {children}
    </RadixTabs.Trigger>
  )
}
