import { Agreements } from 'account/types'
import { useMonitoring, useNetwork } from 'shared/hooks'
import { useQuery } from 'react-query'

const usePendingAgreements = (): [Agreements | null, boolean] => {
  const { get } = useNetwork()
  const { captureException } = useMonitoring()

  const { data: agreement = null, isError: error } = useQuery(
    // see frontend/src/shared/hooks/useAuthenticatedUser.ts:52
    'pending-agreements',
    async () =>
      await get<Agreements>('agreements/pending')
        .then(({ data }) => data)
        .catch((error) => {
          captureException(error)
        }),
    {
      retry: 5,
    },
  )

  return [agreement, error]
}

export { usePendingAgreements }
