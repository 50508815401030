import { UpdateProfileAboutSchema } from 'profile/domain'

export class UpdateProfileAboutDTO {
  constructor(private readonly props: UpdateProfileAboutSchema) {}

  toJSON() {
    return {
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      location: this.props.location,
      personalSite: this.props.personalSite || null,
      gitRepositoriesUrl: this.props.gitRepositoriesUrl || null,
      whatsAppNumber: this.props.whatsAppNumber || null,
    }
  }
}
