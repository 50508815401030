import classNames from 'classnames'
import { Text } from 'shared/components/atoms'
import { ReactComponent as Icon } from 'icons/x-square.svg'

interface FieldErrorProps {
  message?: string
  className?: string
  floatingError?: boolean
}

export const FieldError = ({ message, className, floatingError }: FieldErrorProps) => {
  return (
    <div
      className={classNames('flex items-center gap-2 my-[2px] lg:my-2', {
        'absolute top-9': floatingError,
      })}
    >
      <>
        {Boolean(message) && <Icon className="w-4 h-4 fill-danger-medium stroke-neutral-day" />}
        <Text
          size="text-sm"
          weight="font-normal"
          className={classNames('text-danger-darker', className)}
        >
          {message}&nbsp;
        </Text>
      </>
    </div>
  )
}
