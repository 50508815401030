import { useMutation, useQueryClient } from 'react-query'

import { useAuthenticatedUser, useMonitoring, useNetwork, useToast } from 'shared/hooks'
import {
  UpdateProfileAboutSchema,
  UpdateProfileBioSchema,
  UpdateProfileRolesAndSkillsSchema,
} from 'profile/domain'
import { UpdateProfileAboutDTO, UpdateProfileRolesAndSkillsDTO } from 'profile/dtos'

export const useManageProfile = (profileId: string) => {
  const client = useQueryClient()
  const { post } = useNetwork()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()

  const {
    user: { isSeeker },
  } = useAuthenticatedUser()

  const { mutateAsync: updateProfileAbout, isLoading: isLoadingAbout } = useMutation(
    (payload: UpdateProfileAboutSchema) =>
      post(`/profile/${profileId}/about`, new UpdateProfileAboutDTO(payload)),
    {
      onSuccess: () => {
        client.invalidateQueries('profile')
        client.invalidateQueries(`candidates/${profileId}`)
      },
      onError: (error: RequestError) => {
        const message = error.response?.data.message || 'Unknown error.'
        toastError('Error when updating the profile about: ' + message)

        captureException(error)
      },
    },
  )

  const { mutateAsync: updateProfileBio, isLoading: isLoadingBio } = useMutation(
    (payload: UpdateProfileBioSchema) => post(`/profile/${profileId}/bio`, payload),
    {
      onSuccess: () => {
        client.invalidateQueries('profile')
        client.invalidateQueries(`candidates/${profileId}`)
      },
      onError: (error: RequestError) => {
        const message = error.response?.data.message || 'Unknown error.'
        toastError('Error when updating the profile bio: ' + message)

        captureException(error)
      },
    },
  )

  const { mutateAsync: updateProfileRolesAndSkills, isLoading: isLoadingRolesAndSkills } =
    useMutation(
      (payload: UpdateProfileRolesAndSkillsSchema) =>
        post(
          `/profile/${profileId}/roles-and-skills`,
          new UpdateProfileRolesAndSkillsDTO({ ...payload, isSeeker }),
        ),
      {
        onSuccess: () => {
          client.invalidateQueries('profile')
          client.invalidateQueries(`candidates/${profileId}`)
        },
        onError: (error: RequestError) => {
          const message = error.response?.data.message || 'Unknown error.'
          toastError('Error when updating the profile roles and main skills: ' + message)

          captureException(error)
        },
      },
    )

  return {
    updateProfileAbout,
    updateProfileBio,
    updateProfileRolesAndSkills,
    isLoading: isLoadingAbout || isLoadingBio || isLoadingRolesAndSkills,
  }
}
