import { useEffect, useMemo } from 'react'

import { PrimaryButton, TertiaryButton, Text } from 'shared/components/atoms'
import { EditInput, Modal } from 'shared/components/molecules'

import { useForm } from 'shared/hooks'

import { JobListingSettings } from 'job-listing/domains'
import { ListingInfo } from 'job-listing/domains'

import { schema } from '.'
import { StriderUsersOnly } from 'components/Access/StriderUsersOnly'
import { useUpdateScheduleLinks } from 'matching/hooks'
import { ScheduleLinkChangingBanner } from 'job-listing/components/Banner'

interface JobListingSettingsModalProps {
  jobListing: ListingInfo
  isOpen: boolean
  onClose: () => void
}

export const JobListingSettingsModal = ({
  jobListing,
  isOpen,
  onClose,
}: JobListingSettingsModalProps) => {
  const defaultValues = useMemo(
    () => ({
      introCallScheduleLink: jobListing.introCallScheduleLink,
      finalInterviewScheduleLink: jobListing.finalInterviewScheduleLink,
      introCallRequired: Boolean(jobListing.introCallScheduleLink),
      finalInterviewRequired: Boolean(jobListing.finalInterviewScheduleLink),
    }),
    [jobListing],
  )

  const formControl = useForm({
    schema,
    defaultValues: defaultValues,
  })

  const { getValues, handleSubmit, reset } = formControl

  const { updateScheduleLinks, isLoading } = useUpdateScheduleLinks({ jobListingId: jobListing.id })

  const handleCloseListingSettings = () => {
    reset(defaultValues)
    onClose()
  }

  const onSubmit = handleSubmit(() => {
    const values = getValues() as JobListingSettings
    updateScheduleLinks(values).then(() => handleCloseListingSettings())
  })

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues])

  return (
    <Modal
      mobilePositioning="bottom"
      titleContainerClassName="!py-6 !px-10"
      title="Job listing settings"
      open={isOpen}
      handleClose={handleCloseListingSettings}
      contentContainerClassName="!p-10"
      content={
        <form id="listing-settings-form" onSubmit={onSubmit} className="flex flex-col gap-8">
          <StriderUsersOnly>
            <div className="flex flex-col gap-2">
              <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
                External ID
              </Text>
              <Text size="text-base" weight="font-medium">
                {jobListing.externalId}
              </Text>
            </div>
          </StriderUsersOnly>

          <EditInput
            name="introCallScheduleLink"
            formControl={formControl}
            label="Intro call schedule link"
          />

          <EditInput
            name="finalInterviewScheduleLink"
            formControl={formControl}
            label="Final interview schedule link"
          />

          <ScheduleLinkChangingBanner
            editedScheduleLink={getValues('introCallScheduleLink')}
            scheduleLink={jobListing.introCallScheduleLink}
            type="intro call"
            jobTitle={jobListing.title}
          />

          <ScheduleLinkChangingBanner
            editedScheduleLink={getValues('finalInterviewScheduleLink')}
            scheduleLink={jobListing.finalInterviewScheduleLink}
            type="final interview"
            jobTitle={jobListing.title}
          />
        </form>
      }
      footer={
        <div className="flex-grow w-full flex flex-row gap-4 justify-end">
          <TertiaryButton onClick={handleCloseListingSettings} isLoading={isLoading}>
            Cancel
          </TertiaryButton>
          <PrimaryButton type="submit" form="listing-settings-form" isLoading={isLoading}>
            Save changes
          </PrimaryButton>
        </div>
      }
    />
  )
}
