import { cn } from 'modules/shared'
import { TertiaryButton, Title } from 'shared/components/atoms'
import { ReactComponent as CloseIcon } from 'icons/close.svg'

interface ModalHeaderProps {
  title?: string | React.ReactElement
  showClose?: boolean
  showDismissalConfirmation?: boolean
  withBorders?: boolean
  titleClassName?: string
  titleContainerClassName?: string
  showConfirmation: () => void
  handleClose?: () => void
}

export const ModalHeader = ({
  title,
  showClose,
  showDismissalConfirmation,
  withBorders,
  titleClassName,
  titleContainerClassName,
  showConfirmation,
  handleClose,
}: ModalHeaderProps) => {
  if (!title && !showClose) return null

  const ModalTitle = () => {
    if (!title) return null

    if (typeof title === 'string') {
      return (
        <Title size="large-heading" className={titleClassName}>
          {title}
        </Title>
      )
    }

    return title
  }

  return (
    <div
      className={cn(
        'flex flex-row items-center justify-between',
        {
          'border-b border-neutral-lighter': withBorders,
          'px-5 py-3 sm:px-10 sm:py-7': withBorders,
          'px-5 pt-3 sm:px-10 sm:pt-7': !withBorders,
        },
        titleContainerClassName,
      )}
    >
      <ModalTitle />

      {showClose && (
        <TertiaryButton
          size="md"
          icon={<CloseIcon className="!w-6 !h-6" />}
          className="focus:shadow-none"
          onClick={showDismissalConfirmation ? showConfirmation : handleClose}
        />
      )}
    </div>
  )
}
