import { useEffect, useMemo } from 'react'

import {
  FieldError,
  Label,
  PrimaryButton,
  SelectTags,
  TertiaryButton,
} from 'shared/components/atoms'
import { Modal } from 'shared/components/molecules'
import { ContentWrapper } from 'components/ContentWrapper'
import { MainSkillsSelect } from 'components/molecules/MainSkillsSelect'
import { useProfile } from 'hooks/profile'
import { AccountClaim, useAuthenticatedUser, useForm } from 'shared/hooks'
import { ProfileOverview, Role } from 'pages/JobSeekerProfile/types'
import { UpdateProfileRolesAndSkillsSchema } from 'profile/domain'
import { useManageProfile } from 'profile/hooks'

import { buildDefaultValues, schema } from '.'

interface AboutProps {
  profile: ProfileOverview
  handleClose(): void
}

export const RolesAndSkills = ({ handleClose, profile }: AboutProps) => {
  const {
    user: { claims },
  } = useAuthenticatedUser()

  const { profileRoles } = useProfile()
  const { updateProfileRolesAndSkills, isLoading } = useManageProfile(profile.id)

  const formControl = useForm({
    schema,
    defaultValues: {
      stacks: [],
      openToRoles: [],
    },
  })

  const { handleSubmit, setValue, getError, watch, isDirty, reset } = formControl

  const openToRoles = watch('openToRoles') as Array<Role>

  const roleOptions = useMemo(
    () => profileRoles.filter(({ id }) => !openToRoles.some(({ id: roleId }) => id === roleId)),
    [openToRoles, profileRoles],
  )

  const onSubmit = handleSubmit(async (payload) => {
    await updateProfileRolesAndSkills(payload as UpdateProfileRolesAndSkillsSchema)
    handleClose()
  })

  useEffect(() => {
    reset(buildDefaultValues(profile))
  }, [profile, reset])

  return (
    <Modal
      handleClose={handleClose}
      showDismissalConfirmation={isDirty}
      title="Roles and main skills"
      content={
        <form onSubmit={onSubmit} id="roles-skills-form">
          <ContentWrapper isForm>
            <Label htmlFor="openToRoles" size="text-sm" className="mb-4">
              Which roles are you open to?
            </Label>
            <SelectTags
              name="openToRoles"
              setValue={setValue}
              watch={watch}
              error={getError('openToRoles')}
              options={roleOptions}
              matchDescription
              allowCreation={claims.includes(AccountClaim.ManageProfiles)}
              showDescription={false}
              placeholder="e.g. Full-stack Developer"
              selectedOptions={[]}
            />
          </ContentWrapper>
          <ContentWrapper isForm>
            <MainSkillsSelect formControl={formControl} />
            <FieldError message={getError('stacks')} />
          </ContentWrapper>
        </form>
      }
      footer={
        <>
          <TertiaryButton size="md" onClick={handleClose} className="w-full md:w-fit">
            Cancel
          </TertiaryButton>
          <PrimaryButton
            size="md"
            type="submit"
            form="roles-skills-form"
            isLoading={isLoading}
            className="w-full md:w-fit"
          >
            Save
          </PrimaryButton>
        </>
      }
    />
  )
}
