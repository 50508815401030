import { useMutation } from 'react-query'
import { useMonitoring, useNetwork, useToast } from 'shared/hooks'
import { useTracking } from 'tracking'
import { MatchingFinalInterviewRequested } from 'tracking/events/matchings'

interface Props {
  matchingId: string
  jobListingId: string
}

export const useRequestFinalInterview = ({ matchingId, jobListingId }: Props) => {
  const { post } = useNetwork()
  const { toastError, toastSuccess } = useToast()
  const { captureException } = useMonitoring()
  const { trackEvent } = useTracking()

  const { mutateAsync: requestFinalInterview, isLoading } = useMutation(
    (feedback: string) => {
      trackEvent(new MatchingFinalInterviewRequested(matchingId, jobListingId))

      return post(`/listings/${jobListingId}/matchings/${matchingId}/final-interview`, {
        feedback,
      })
    },
    {
      onSuccess: () => {
        toastSuccess('Final interview requested!')
      },
      onError: (error: RequestError) => {
        toastError(
          `Could not request the final interview: ${error.response?.data.message ?? 'Unknown error'}`,
        )
        captureException(error)
      },
    },
  )

  return { requestFinalInterview, isLoading }
}
