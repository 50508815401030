export enum MatchingStatus {
  ScreeningApproved = 'screening_approved',
  Screening = 'screening',
  Saved = 'saved',
  DoubleChecking = 'double_checking',
  Shortlisted = 'shortlisted',
  Matched = 'matched',
  IntroCallRequested = 'intro_call_requested',
  FinalInterviewRequested = 'final_interview_requested',
  Rejected = 'rejected',
  NotAFit = 'not_a_fit',
  Hired = 'hired',
}

export const clientFacingMatchingStatus = [
  MatchingStatus.Matched,
  MatchingStatus.IntroCallRequested,
  MatchingStatus.Rejected,
  MatchingStatus.Hired,
]

export const internalMatchingStatus = [
  MatchingStatus.Saved,
  MatchingStatus.Shortlisted,
  MatchingStatus.NotAFit,
]

export const matchingSuccessMessages: Record<MatchingStatus, string> = {
  [MatchingStatus.Screening]: 'Screening sent.',
  [MatchingStatus.ScreeningApproved]: '',
  [MatchingStatus.DoubleChecking]: '',
  [MatchingStatus.Saved]: 'Candidate saved.',
  [MatchingStatus.Shortlisted]: 'Candidate shortlisted.',
  [MatchingStatus.Matched]: 'Matching created.',
  [MatchingStatus.IntroCallRequested]: '',
  [MatchingStatus.FinalInterviewRequested]: '',
  [MatchingStatus.Rejected]: '',
  [MatchingStatus.NotAFit]: 'Candidate hidden.',
  [MatchingStatus.Hired]: '',
}

export const matchingStatusLabels: Record<MatchingStatus, string> = {
  [MatchingStatus.Matched]: 'Matched',
  [MatchingStatus.NotAFit]: 'Not a fit',
  [MatchingStatus.ScreeningApproved]: 'Screening approved',
  [MatchingStatus.Screening]: 'Screening',
  [MatchingStatus.Saved]: 'Saved',
  [MatchingStatus.DoubleChecking]: 'Double checking',
  [MatchingStatus.Shortlisted]: 'Shortlisted',
  [MatchingStatus.IntroCallRequested]: 'Intro call',
  [MatchingStatus.FinalInterviewRequested]: 'Final interview',
  [MatchingStatus.Rejected]: 'Rejected',
  [MatchingStatus.Hired]: 'Hired',
}
