// eslint-disable-next-line no-restricted-imports
import { useUser, useAuth as useClerkAuth } from '@clerk/clerk-react'
import { useLayoutEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'

import { Roles } from 'account/enums'
import { useNetwork } from 'shared/hooks'
import { User, UserResponse } from './index'

export const nullUser: User = {
  firstName: '',
  lastName: '',
  fullName: '',
  email: '',
  role: undefined,
  isManager: false,
  isSeeker: false,
  isEmployer: false,
  isRecruiter: false,
  isViewerOnly: false,
  isStriderStaff: false,
  isEnglishEvaluator: false,
  isEnglishVettingAdmin: false,
  isJobSeekerProfilesManager: false,
  isMatchingsManager: false,
  isJobListingSettingsManager: false,
  company: null,
  isOnboarded: false,
  accountId: '',
  isCommunicationAllowed: false,
  helpChatToken: '',
  claims: [],
  demoModeActive: false,
  imageUrl: '',
  requests: [],
  links: { console: undefined, createJobListing: undefined },
}

const buildFromResponse = (
  payload: UserResponse,
): Omit<User, 'firstName' | 'lastName' | 'imageUrl' | 'fullName'> => ({
  email: payload.email,
  role: payload.role,
  isManager: payload.role === Roles.EmployerOwner,
  isSeeker: payload.role === Roles.JobSeeker,
  isEmployer: [Roles.EmployerOwner, Roles.EmployerViewer].includes(payload.role),
  isRecruiter: payload.role === Roles.Recruiter,
  isViewerOnly: payload.role === Roles.EmployerViewer,
  isOnboarded: [
    Roles.JobSeeker,
    Roles.EmployerOwner,
    Roles.EmployerViewer,
    Roles.Recruiter,
  ].includes(payload.role),
  company: payload.company,
  accountId: payload.accountId,
  helpChatToken: payload.intercomHmacToken,
  isCommunicationAllowed: payload.isCommunicationAllowed,
  isStriderStaff: payload.isStriderStaff,
  isEnglishEvaluator: payload.isEnglishEvaluator,
  isEnglishVettingAdmin: payload.isEnglishVettingAdmin,
  isJobSeekerProfilesManager: payload.isJobSeekerProfilesManager,
  isMatchingsManager: payload.isMatchingsManager,
  isJobListingSettingsManager: payload.isJobListingSettingsManager,
  claims: payload.claims || [],
  demoModeActive: payload.demoModeActive,
  requests: payload.requests,
  links: payload.links,
})

export const useAuth = () => {
  const client = useQueryClient()
  const { get } = useNetwork()
  const { user: clerkUser } = useUser()
  const { getToken, signOut } = useClerkAuth()
  const { firstName, lastName, primaryEmailAddress, fullName, imageUrl } = clerkUser || {}

  const [isLoaded, setIsLoaded] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [user, setUser] = useState<User>(nullUser)

  const { isLoading } = useQuery(
    'accounts/me',
    () => {
      setIsLoaded(false)
      setNotFound(false)
      return get<UserResponse>('accounts/me')
        .then(({ data }) => {
          patchUser(buildFromResponse(data))
        })
        .catch(({ response }) => {
          setNotFound(response.status === 404)
        })
        .finally(() => setIsLoaded(true))
    },
    { cacheTime: Infinity, staleTime: Infinity },
  )

  const supplementalTermsUrl =
    user.company?.id === 183
      ? String(process.env.REACT_APP_ZAMP_OFFER_SUPPLEMENTAL_TERM_URL)
      : String(process.env.REACT_APP_OFFER_SUPPLEMENTAL_TERM_URL)
  const supplementalTermsLabel =
    user.company?.id === 183 ? 'Supplemental Terms - Talent Engagement' : 'Talent Engagement Terms'

  const patchUser = (patch: Partial<User>) => {
    setUser((current) => Object.assign({}, current, patch))
  }

  useLayoutEffect(() => {
    patchUser({
      firstName,
      lastName,
      email: primaryEmailAddress?.emailAddress,
      fullName,
      imageUrl,
    })
  }, [firstName, lastName, primaryEmailAddress?.emailAddress, fullName, imageUrl])

  const refresh = async () => {
    // see frontend/src/pages/PendingAgreementChecker/usePendingAgreements.tsx
    await client.invalidateQueries('pending-agreements')
    await client.invalidateQueries('agreements/latest-accepted')
    await client.invalidateQueries('accounts/me')
  }

  return {
    user,
    isLoading,
    isLoaded,
    notFound,
    refresh,
    isPending: !isLoaded || isLoading,
    supplementalTermsUrl,
    supplementalTermsLabel,
    signOut,
    getToken,
  }
}
