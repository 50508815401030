import { useState } from 'react'

import { TertiaryButton } from 'shared/components/atoms'
import { Drawer } from 'shared/components/molecules'
import { Paragraph } from 'shared/components/atoms'
import { Title } from 'shared/components/atoms'

import { Actions, Candidate, ProfileMatcher, ScreeningDrawer } from '../..'

import { ReactComponent as RightArrowIcon } from 'icons/right-arrow.svg'
import { ReactComponent as LeftArrowIcon } from 'icons/left-arrow.svg'
import { ReactComponent as CloseIcon } from 'icons/close.svg'

import { Candidate as CandidateDomain } from 'candidates-search/domain'
import { ProfileSection } from 'candidates-search/enums'

interface ProfileDrawerProps {
  candidate: CandidateDomain | null | undefined
  prevButtonDisabled: boolean
  nextButtonDisabled: boolean
  onClose: () => void
  onPrevious: () => void
  onNext: () => void
  navigationLabel: JSX.Element
  isLoading: boolean
  initialSection: ProfileSection
  setDefaultSection: (section: ProfileSection) => void
}

export const ProfileDrawer = ({
  candidate,
  onClose,
  prevButtonDisabled,
  nextButtonDisabled,
  onPrevious,
  onNext,
  navigationLabel,
  isLoading,
  initialSection,
  setDefaultSection,
}: ProfileDrawerProps) => {
  const [isScreening, setIsScreening] = useState(false)
  const [isMatching, setIsMatching] = useState(false)

  if (!candidate) return null

  const handleOpenScreening = () => setIsScreening(true)
  const handleCloseScreening = () => setIsScreening(false)

  const handleOpenMatcher = () => setIsMatching(true)
  const handleCloseMatcher = () => setIsMatching(false)

  if (isScreening) {
    return <ScreeningDrawer candidate={candidate} isOpen onClose={handleCloseScreening} />
  }

  if (isMatching) {
    return <ProfileMatcher candidate={candidate} onBack={handleCloseMatcher} />
  }

  return (
    <Drawer
      position="right"
      isOpen
      handleClose={onClose}
      customTitle={({ onClose }) => (
        <div className="flex flex-row justify-between px-10 py-6 border-b border-neutral-light">
          <div className="flex flex-row items-center gap-2">
            <TertiaryButton
              onClick={onClose}
              icon={<CloseIcon className="!w-6 !h-6" />}
              className="focus:shadow-none"
            />
            <Title size="heading" color="text-neutral-darkest" className="!font-medium">
              Profile details
            </Title>
          </div>
          <div className="flex flex-row items-center gap-2">
            <Paragraph size="body-sm" weight="font-normal" className="text-neutral-dark">
              {navigationLabel}
            </Paragraph>
            <TertiaryButton
              icon={<LeftArrowIcon />}
              onClick={onPrevious}
              disabled={prevButtonDisabled}
            />
            <TertiaryButton
              icon={<RightArrowIcon />}
              onClick={onNext}
              disabled={nextButtonDisabled}
            />
          </div>
        </div>
      )}
      cta={
        <Actions
          profile={candidate}
          onMatchClick={handleOpenMatcher}
          onScreeningClick={handleOpenScreening}
        />
      }
    >
      <Candidate
        searchCandidate={candidate}
        isLoading={isLoading}
        initialSection={initialSection}
        setDefaultSection={setDefaultSection}
      />
    </Drawer>
  )
}
