import { useState } from 'react'

import { DestructiveButton, PrimaryButton, SecondaryButton, Text } from 'shared/components/atoms'
import { AlertDialog } from 'shared/components/molecules'

import { useDeleteMatching } from 'hooks/matchings'
import { MatchingStatus } from 'matching/enums'

interface FooterProps {
  isLoading: boolean
  matchingId: string
  status: MatchingStatus
  jobListingId: string
  onClose: () => void
}

export const Footer = ({ isLoading, matchingId, status, jobListingId, onClose }: FooterProps) => {
  const { canDeleteMatching, deleteMatching } = useDeleteMatching(jobListingId, matchingId)
  const isDeleteVisible = canDeleteMatching(status)

  const [isAlertDialogOpen, setAlertDialogOpen] = useState(false)

  const openDialog = () => setAlertDialogOpen(true)
  const closeDialog = () => setAlertDialogOpen(false)

  return (
    <div className="flex grow w-full flex-col gap-2 md:flex-row-reverse md:justify-between">
      <div className="flex grow flex-col-reverse gap-2 md:flex-row md:gap-4 md:place-content-end">
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <PrimaryButton type="submit" isLoading={isLoading} form="settings-form">
          Save changes
        </PrimaryButton>
      </div>
      {isDeleteVisible && (
        <AlertDialog
          isOpen={isAlertDialogOpen}
          title="Are you sure?"
          description={
            <Text size="text-base" weight="font-normal">
              This action cannot be undone.
            </Text>
          }
          confirmText="Yes, I want to delete it"
          trigger={<DestructiveButton onClick={openDialog}>Delete</DestructiveButton>}
          onConfirmClick={deleteMatching}
          onCancelClick={closeDialog}
        />
      )}
    </div>
  )
}
