import { Suspense, lazy } from 'react'
import classNames from 'classnames'
import { EditorState, RawDraftContentState } from 'draft-js'
import { useEffect, useState } from 'react'
import { UseFormSetValue } from 'react-hook-form'

import './RichTextEditor.scss'
import { cn } from 'modules/shared'
import { textareaStyles } from '../Textarea'
import { FieldError } from '../FieldError'
import { CustomHighlight } from './CustomActions'

const Editor = lazy(() =>
  import(
    /* webpackChunkName: 'react-draft-wysiwyg', webpackPreload: true */
    'react-draft-wysiwyg'
  ).then((module) => ({ default: module.Editor })),
)

const toolbarConfig = ({ showBoldOption }: { showBoldOption: boolean }) => ({
  options: ['inline', 'list'],
  inline: {
    inDropdown: false,
    options: showBoldOption ? ['italic', 'bold'] : ['italic'],
    bold: showBoldOption
      ? {
          icon: '/icons/editor/bold.svg',
        }
      : undefined,
    italic: {
      icon: '/icons/editor/italic.svg',
    },
  },
  list: {
    options: ['unordered', 'ordered'],
    unordered: {
      icon: '/icons/editor/ul.svg',
    },
    ordered: {
      icon: '/icons/editor/ol.svg',
    },
  },
})

const BACKGROUND_COLOR = 'rgba(33,107,217,0.2)'

const styleMap = {
  HIGHLIGHT: {
    backgroundColor: BACKGROUND_COLOR,
  },
}

interface RichTextEditorProps {
  ariaLabel?: string
  defaultValue?: string | null
  setValue?: UseFormSetValue<Record<string, string>>
  children?: JSX.Element
  error?: string
  name: string
  readOnly?: boolean
  warningOnly?: boolean
  omitErrors?: boolean
  className?: string
  showBoldOption?: boolean
  showHighlightOption?: boolean
}

export const RichTextEditor = ({
  ariaLabel,
  defaultValue,
  setValue,
  error,
  children,
  name,
  readOnly,
  warningOnly,
  omitErrors,
  className,
  showBoldOption,
  showHighlightOption,
}: RichTextEditorProps) => {
  const buildEditorState = async () => {
    const { ContentState, EditorState } = await import(/* webpackPrefetch: true */ 'draft-js')
    const { default: htmlToDraft } = await import(/* webpackPrefetch: true */ 'html-to-draftjs')

    const blocks = htmlToDraft(defaultValue ?? '')
    const state = ContentState.createFromBlockArray(blocks.contentBlocks)
    let editorState = EditorState.createWithContent(state)
    editorState = EditorState.moveSelectionToEnd(editorState)

    return editorState
  }

  const [editorState, setEditorState] = useState<EditorState | undefined>()
  const [defaultEditorState, setDefaultEditorState] = useState<EditorState | undefined>()

  const renderable = Boolean(editorState || defaultEditorState)

  const defaultStyle = textareaStyles(
    error,
    'warning',
    'w-full min-h-[320px] px-4 py-2 pt-12 !h-auto !text-base',
  )
  const classes = readOnly ? 'w-full !h-auto !text-sm' : defaultStyle

  const handleContentStateChange = (state: RawDraftContentState) => {
    import(/* webpackPrefetch: true */ 'draftjs-to-html').then(({ default: draftToHtml }) => {
      if (setValue) setValue(name, draftToHtml(state), { shouldValidate: true })
    })
  }

  useEffect(() => {
    buildEditorState().then((editorState) => {
      if (readOnly) return setEditorState(editorState)

      setDefaultEditorState(editorState)
    })
  }, [defaultValue])

  const toolbar = toolbarConfig({
    showBoldOption: Boolean(showBoldOption),
  })

  const props = {} as { editorState: EditorState | undefined }
  if (readOnly) props.editorState = editorState
  return (
    <Suspense fallback={null}>
      {renderable && (
        <Editor
          ariaLabel={ariaLabel}
          spellCheck
          defaultEditorState={defaultEditorState}
          onEditorStateChange={setEditorState}
          toolbar={toolbar}
          editorClassName={cn(classes, className)}
          wrapperClassName={classNames({ 'warnings--enabled': warningOnly && error })}
          onContentStateChange={handleContentStateChange}
          toolbarHidden={readOnly}
          readOnly={readOnly}
          stripPastedStyles
          toolbarCustomButtons={
            showHighlightOption
              ? [<CustomHighlight backgroundColor={BACKGROUND_COLOR} key="custom-highlight" />]
              : undefined
          }
          {...props}
          customStyleMap={styleMap}
        />
      )}
      {children}
      {!omitErrors && !readOnly && !warningOnly && <FieldError message={error} />}
    </Suspense>
  )
}
