import { InputHTMLAttributes, useRef, useState } from 'react'
import { Input, Label, SecondaryButton } from 'shared/components/atoms'
import { FormControl } from 'shared/hooks'
import { ReactComponent as EditIcon } from 'icons/edit.svg'

interface EditInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  formControl: FormControl
  label: string
}

export const EditInput = ({ name, formControl, label, ...props }: EditInputProps) => {
  const [disableField, setDisableField] = useState(true)
  const inputRef = useRef<HTMLInputElement>(null)

  const ariaLabel = `Edit ${label}`

  const handleEdit = () => {
    setDisableField(false)
    setTimeout(() => {
      inputRef.current?.focus()
    }, 0)
  }

  return (
    <div className="flex gap-4 items-end">
      <div className="flex flex-col grow">
        <Label htmlFor={name} size="text-sm" weight="font-medium">
          {label}
        </Label>
        <Input
          ref={inputRef}
          name={name}
          disabled={disableField}
          register={formControl.register}
          {...props}
          showFieldError={!disableField}
          floatingError
        />
      </div>
      {disableField && (
        <SecondaryButton
          size="md"
          icon={<EditIcon className="h-[16px] w-[16px] stroke-neutral-darkest" />}
          onClick={handleEdit}
          aria-label={ariaLabel}
        />
      )}
    </div>
  )
}
