import { AccountRequest, Roles } from 'account/enums'
import { createContext, memo, ReactNode, useContext, useEffect, useMemo } from 'react'
import { useTracking } from 'tracking'
// eslint-disable-next-line no-restricted-imports
import { nullUser, useAuth } from './useAuth'

// @todo - refactor to use domain classes
export enum HiringMode {
  Direct = 'direct',
  ThroughUs = 'through us',
  DirectHelloSign = 'direct hellosign',
  ThroughUsEmployerOfRecord = 'through us employer of record',
}

export enum AccountClaim {
  Staff = '0e4f2c1024a6eddb2042566566e048e1',
  Teleport = 'a681367170a38ce7f3be3a05b56e0d70',
  ManageJobListingSettings = 'a3fc15c92546d7ac62d1a4a49bb48a80',
  ManageMatchingSettings = '817428b952c15c35827570769ab05491',
  ManageMatchings = '40e6503df85d263d602703ad404547c8',
  ToggleDemoMode = 'df7c2384829911cfb119ab4d3274bc5e',
  ManageProfiles = '0ce0ff5976da4f75113f4ca2ae0f6068',
  EnglishCheckEvaluations = '63ef5735bbc6ba16bb9cee1634552cc5',
  EnglishCheckManagement = '1b4b80971c56e02e178ada879b5a407f',
  EnglishCheckViewEvaluation = '8912d240a3da740d08ab35f28ccdd911',
  ToggleBlurMode = 'd341927cad9ce0d44c3e5c4badce245b',
  WorkspaceManagement = '4447a8631040d30a5a113d7f9befc817',
  ScreeningManagement = '65eb1921ddfa4e776be531cb2f7012d5',
  ManageProfileSettings = 'a358c1213f01de2f5c691afd03f7d174',
  CreateMatchings = '11d30ec2b7fa9046724130f03b23b18d',
  ViewJobListingValues = '0b2541947ab50b00207ea5e0ece68399',
  ManageAssessmentTags = 'e2f453c90131584b2732615a50fbb601',
  ManageProfileProficiencies = '9b5f10bce5358853f735eb84867bc861',
  ViewProfileAssessmentTags = 'd4e72d97cd268bf78f9965511aa1b311',
  ViewProfileProficiencies = '256ec00cd9b5077c529226446e29efa3',
  PauseJobListings = '0c0441009191d21c9f6df0973575b1a0',
  CloseJobListings = 'c6a5375323fb60ec9c52ef4d9e492b46',
  ManageMatchingFlags = 'aba59be29af1876120e64ba9d8108180',
  ViewMatchingFlags = '324dbdd56feaa3830d8e5824375ec082',
  TimelineView = '2104425e44bbbccb7bc6c246196eb8b7',
  ReactivateMatching = '7f5f3fbfa6d515d310629e5f451876aa',
  ViewAllAssessmentResultOptions = 'b7faf41ec55c9b5de18bcc56346f99f1',
  Console = '32a945d946a092728de51769354d1db6',
}

type Company = {
  name: string
  website: string
  id: number
  candidatesTrackerUrl?: string
  approved: boolean
  canSkipListingTerms: boolean
  hiringThroughUs: boolean
  hiringMode: HiringMode
  jobOffersCount: number
}

type AccountRequestRecord = {
  id: string
  type: AccountRequest
  pending: boolean
  scheduledFor: string
}

export type User = {
  firstName: string | null
  lastName: string | null
  fullName: string | null
  email: string
  role: Roles | undefined
  isManager: boolean
  isSeeker: boolean
  isEmployer: boolean
  isRecruiter: boolean
  isViewerOnly: boolean
  isOnboarded: boolean
  isStriderStaff: boolean
  isEnglishEvaluator: boolean
  isEnglishVettingAdmin: boolean
  isJobSeekerProfilesManager: boolean
  isMatchingsManager: boolean
  isJobListingSettingsManager: boolean
  company: Company | null
  accountId: string
  isCommunicationAllowed: boolean
  helpChatToken: string
  claims: Array<AccountClaim>
  demoModeActive: boolean
  imageUrl: string
  requests: Array<AccountRequestRecord>
  links: Optional<{
    console: Optional<string>
    createJobListing: Optional<string>
  }>
}

export type UserResponse = {
  id: number
  accountId: string
  role: Roles
  email: string
  company: Company | null
  intercomHmacToken: string
  isCommunicationAllowed: boolean
  isEnglishEvaluator: boolean
  isEnglishVettingAdmin: boolean
  isStriderStaff: boolean
  isJobSeekerProfilesManager: boolean
  isMatchingsManager: boolean
  isJobListingSettingsManager: boolean
  claims: Array<AccountClaim>
  demoModeActive: boolean
  jobOffersCount?: number
  requests: Array<AccountRequestRecord>
  links: Optional<{
    console: Optional<string>
    createJobListing: Optional<string>
  }>
}

export const initialUserState = {
  user: nullUser,
  signOut: () => Promise.resolve(),
  deletionRequest: null as Nullable<AccountRequestRecord>,
  isLoaded: false,
  notFound: false,
  supplementalTermsUrl: '',
  supplementalTermsLabel: '',
  refresh: () => Promise.resolve(),
  getToken: ({ template }: { template: string }) => Promise.resolve(null as Nullable<string>),
}

export const UserContext = createContext(initialUserState)

export const UserContextProvider = memo(function UserContextProvider({
  children,
}: {
  children: ReactNode
}) {
  const { identifyUser } = useTracking()
  const {
    user,
    isLoaded,
    notFound,
    signOut,
    supplementalTermsUrl,
    supplementalTermsLabel,
    refresh,
    getToken,
  } = useAuth()

  const deletionRequest = useMemo(() => {
    const deletionRequest = user.requests?.find(
      ({ pending, type }) => pending && type === AccountRequest.Deletion,
    )
    return deletionRequest ?? null
  }, [user])

  const userContext = useMemo(
    () => ({
      user,
      isLoaded,
      notFound,
      deletionRequest,
      signOut,
      supplementalTermsUrl,
      supplementalTermsLabel,
      refresh,
      getToken,
    }),
    [
      user,
      isLoaded,
      notFound,
      deletionRequest,
      signOut,
      supplementalTermsUrl,
      supplementalTermsLabel,
      refresh,
      getToken,
    ],
  )

  useEffect(() => {
    identifyUser(user)
  }, [user.accountId])

  return <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
})

export function useAuthenticatedUser() {
  return useContext(UserContext)
}
