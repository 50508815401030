import { Link } from 'react-router-dom'

import { ReactComponent as ChevronDownIcon } from 'icons/chevron-down.svg'
import { OrganizationIcon } from 'shared/components/molecules'
import { CompaniesSelector } from '../CompaniesSelector'
import { useState } from 'react'
import { Suggestion } from 'shared/components/atoms'
import { useTeleport } from 'hooks/teleport'
import { AccountClaim, useAuthenticatedUser } from 'shared/hooks'
import { ConfirmationDialog } from '../ConfirmationDialog'
import { LogoIcon } from '../LogoIcon'
import { Wrapper } from '../Wrapper'

export const Logo = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState<Suggestion | null>(null)

  const { user } = useAuthenticatedUser()
  const { teleport, isTeleporting } = useTeleport()

  const canTeleport = user.isEmployer && user.claims.includes(AccountClaim.Teleport)
  const showConfirmation = Boolean(selectedOption)
  const website = user.company?.website ?? null
  const clientName = user.company?.name!

  const logoIcon = (
    <Link
      to="/"
      className="
        flex absolute inset-0 h-full w-full
        items-center justify-center md:ml-8 md:relative
      "
    >
      <LogoIcon teleportable={canTeleport} />
    </Link>
  )

  const handleSelection = async (option: Suggestion) => {
    setMenuOpen(false)
    setSelectedOption(option)
  }

  const handleConfirmation = () => {
    teleport(selectedOption?.id as number).then(() => {
      window.location.replace('/')
    })
  }

  if (!canTeleport) return <Wrapper>{logoIcon}</Wrapper>

  return (
    <Wrapper onOpenMenu={() => setMenuOpen(true)}>
      {logoIcon}
      <span className="hidden md:flex text-white font-medium">+</span>
      <div
        className="flex gap-2 items-center text-white relative"
        onClick={() => setMenuOpen(true)}
      >
        <OrganizationIcon domain={website} size="sm" />
        <span className="max-w-[120px] md:max-w-[200px] truncate text-ellipsis text-white">
          {clientName}
        </span>
        <ChevronDownIcon className="w-4 h-4 stroke-white" />
        {menuOpen && (
          <CompaniesSelector onSelect={handleSelection} onBlur={() => setMenuOpen(false)} />
        )}
      </div>
      <ConfirmationDialog
        open={showConfirmation}
        onCancel={() => setSelectedOption(null)}
        onConfirm={handleConfirmation}
        clientName={selectedOption?.label!}
        isLoading={isTeleporting}
      />
    </Wrapper>
  )
}
