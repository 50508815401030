import { PrimaryButton } from 'shared/components/atoms'
import { Title } from 'shared/components/atoms'
import { useAuthenticatedUser } from 'shared/hooks'
import { useBlacklist } from 'shared/hooks'

import { ReactComponent as PlusIcon } from 'icons/plus.svg'
import { useNavigate } from 'react-router-dom'

interface HeaderProps {}

export const Header = ({}: HeaderProps) => {
  const { user } = useAuthenticatedUser()
  const { isUserBlocked } = useBlacklist(user)
  const navigate = useNavigate()

  const handleCreateListing = () => {
    if (user.links?.createJobListing) {
      window.open(user.links.createJobListing, '_blank')
      return
    }

    navigate('/listings/create')
  }

  return (
    <header className="flex items-center justify-between mb-11 mt-4 md:mt-6">
      <Title size="large-heading">Job listings</Title>
      {user.isManager && !isUserBlocked && (
        <PrimaryButton size="md" onClick={handleCreateListing}>
          <PlusIcon className="stroke-white h-6 w-6" />{' '}
          <span className="text-inherit hidden md:block">New job listing</span>
        </PrimaryButton>
      )}
    </header>
  )
}
