import { useContext } from 'react'

import { SearchContext } from 'candidates-search/contexts'
import { Candidate } from 'candidates-search/domain'
import { Tab } from 'candidates-search/enums'

import { Hide, Save, Share, Shortlist, UndoMatching } from '.'
import { MoveToScreening } from './MoveToScreening'
import { MoveToDoubleChecking } from './MoveToDoubleChecking'

interface ActionsProps {
  profile: Candidate
  onMatchClick?: () => void
  onScreeningClick?: () => void
}

export const Actions = ({ profile, onMatchClick, onScreeningClick }: ActionsProps) => {
  const { tab } = useContext(SearchContext)

  const actions =
    {
      [Tab.AllCandidates]: (
        <>
          <Save profile={profile} />
          <MoveToScreening profile={profile} onClick={onScreeningClick} />
          <MoveToDoubleChecking profile={profile} />
          <Hide profile={profile} />
        </>
      ),
      [Tab.Saved]: (
        <>
          <MoveToScreening profile={profile} onClick={onScreeningClick} />
          <MoveToDoubleChecking profile={profile} />
          <Hide profile={profile} />
        </>
      ),
      [Tab.Screening]: (
        <>
          <Save profile={profile} />
          <MoveToDoubleChecking profile={profile} />
          <Shortlist profile={profile} />
          <Hide profile={profile} />
        </>
      ),
      [Tab.DoubleChecking]: (
        <>
          <Shortlist profile={profile} />
          <Hide profile={profile} />
        </>
      ),
      [Tab.Shortlisted]: (
        <>
          <Share profile={profile} onMatchClick={onMatchClick} />
          <Hide profile={profile} />
        </>
      ),
      [Tab.Matched]: null,
      [Tab.NotAFit]: <UndoMatching profile={profile}>Show on results</UndoMatching>,
    }[tab] || null

  if (!actions) return null

  return (
    <div
      className="flex flex-wrap items-center justify-start gap-4"
      onClick={(ev) => ev.stopPropagation()}
    >
      {actions}
    </div>
  )
}
