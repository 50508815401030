import { UpdateProfileRolesAndSkillsSchema } from 'profile/domain'

export class UpdateProfileRolesAndSkillsDTO {
  constructor(private readonly props: UpdateProfileRolesAndSkillsSchema & { isSeeker: boolean }) {}

  private get stacks() {
    if (!this.props.stacks.length) return

    return this.props.stacks
      .filter(({ stackId }) => Number(stackId) !== 0)
      .map(({ stackId, yearsOfExperience }) => ({
        stackId: Number(stackId),
        experience: Number(yearsOfExperience),
      }))
  }

  private get openToRoles() {
    if (!this.props.openToRoles.length) return

    return this.props.openToRoles
      .filter(({ id }) => id !== null && id >= 0)
      .map(({ id }) => Number(id))
  }

  private get otherPositionDescription() {
    if (this.props.isSeeker || !this.props.openToRoles) return

    return this.props.openToRoles
      .filter(({ id }) => id === null || id < 0)
      .map(({ label }: { label: string }) => label)
      .join(', ')
  }

  toJSON() {
    return {
      profileStacks: this.stacks,
      interestRoles: this.openToRoles,
      otherPositionDescription: this.otherPositionDescription,
    }
  }
}
