import { CandidateContext } from 'contexts/candidate'
import { useAuthenticatedUser } from 'shared/hooks'
import { useContext } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

export const JobSeekerRoutes = () => {
  const navigate = useNavigate()
  const { candidate } = useContext(CandidateContext)

  const { user, isLoaded } = useAuthenticatedUser()

  if (!isLoaded) return null

  // out of bounds
  if (candidate.outOfBounds?.byCountry) navigate('/welcome-out-of-bounds')
  if (candidate.outOfBounds?.byOpenToRole) navigate('/welcome-unsupported')

  if (user.isSeeker) return <Outlet />

  // not a job seeker
  if (user.accountId) navigate('/')

  return null
}
