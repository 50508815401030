import { ToggleFormField } from 'shared/components/atoms'
import { AccountClaim, FormControl, useAuthenticatedUser } from 'shared/hooks'

interface TalentReviewToggleProps {
  formControl: FormControl
}

export const TalentReviewToggle = ({ formControl }: TalentReviewToggleProps) => {
  const {
    user: { claims },
  } = useAuthenticatedUser()

  if (!claims.includes(AccountClaim.ManageJobListingSettings)) return null

  return (
    <ToggleFormField
      name="isTalentReview"
      formControl={formControl}
      label="Enable Talent Review mode"
      description="Talent Review listings have custom flows in the app such as skip e-mail sending for candidates."
    />
  )
}
