import { useAuthenticatedUser } from 'shared/hooks'

interface HiddenForDemoAccountsProps {
  children: JSX.Element
}

export const HiddenForDemoAccounts = ({ children }: HiddenForDemoAccountsProps) => {
  const { user } = useAuthenticatedUser()
  const companyName = String(user.company?.name).toLowerCase()
  const isDemoAccount = companyName.includes('demo')

  if (isDemoAccount) return null

  return children
}
