import { lazy, Suspense, useMemo } from 'react'
import { useAuthenticatedUser } from 'shared/hooks'
import { useLocation } from 'react-router-dom'
import { useReferralPageSeen } from './useReferralPageSeen'

const CoinsIcon = lazy(() =>
  import('icons/coins-stacked-03.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)
const BriefcaseIcon = lazy(() =>
  import('icons/briefcase-02.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)
const BriefcaseOutlinedIcon = lazy(() =>
  import('icons/briefcase-02-outlined.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)
const SearchIcon = lazy(() =>
  import('icons/navbar/candidates-search.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)
const SearchFilledIcon = lazy(() =>
  import('icons/navbar/candidates-search-filled.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)
const HomeOutlinedIcon = lazy(() =>
  import('icons/home-line-outlined.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)
const HomeIcon = lazy(() =>
  import('icons/home-line.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)
const ShareIcon = lazy(() =>
  import('icons/share-07-filled.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)
const ShareOutlinedIcon = lazy(() =>
  import('icons/share-07.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)
const PassportIcon = lazy(() =>
  import('icons/profile/passport.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)
const PassportOutlinedIcon = lazy(() =>
  import('icons/profile/passport-outlined.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)
const StarsIcon = lazy(() =>
  import('icons/star-06.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)
const StarsOutlinedIcon = lazy(() =>
  import('icons/benefits/star-06-outlined.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)
const SettingsIcon = lazy(() =>
  import('icons/settings-04.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)
const SettingsFilledIcon = lazy(() =>
  import('icons/navbar/settings-filled.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)

export const useNavBar = ({ iconClassName }: { iconClassName: string }) => {
  const { user } = useAuthenticatedUser()
  const location = useLocation()
  const { isSeen, setSeen } = useReferralPageSeen()

  const navigationOptions = useMemo(
    () =>
      [
        {
          icon: (
            <Suspense fallback={null}>
              <BriefcaseOutlinedIcon className={iconClassName} />
            </Suspense>
          ),
          activeIcon: (
            <Suspense fallback={null}>
              <BriefcaseIcon className={iconClassName} />
            </Suspense>
          ),
          label: 'Job listings',
          to: '/',
          isActive: location.pathname === '/' || Boolean(location.pathname.match(/listings/)),
          constraints: [user.isOnboarded, user.isEmployer],
        },
        {
          icon: (
            <Suspense fallback={null}>
              <HomeOutlinedIcon className={iconClassName} />
            </Suspense>
          ),
          activeIcon: (
            <Suspense fallback={null}>
              <HomeIcon className={iconClassName} />
            </Suspense>
          ),
          label: 'Home',
          to: '/',
          isActive: location.pathname === '/',
          constraints: [user.isOnboarded, user.isSeeker],
        },
        {
          icon: (
            <Suspense fallback={null}>
              <SettingsIcon className="stroke-white" />
            </Suspense>
          ),
          activeIcon: (
            <Suspense fallback={null}>
              <SettingsFilledIcon className={iconClassName} />
            </Suspense>
          ),
          label: 'Settings',
          to: '/settings',
          isActive: Boolean(location.pathname.match(/settings/)),
          constraints: [user.isOnboarded, user.isEmployer],
        },
        {
          icon: (
            <Suspense fallback={null}>
              <SearchIcon />
            </Suspense>
          ),
          activeIcon: (
            <Suspense fallback={null}>
              <SearchFilledIcon className={iconClassName} />
            </Suspense>
          ),
          label: 'Search',
          to: '/candidates/search',
          isActive: Boolean(location.pathname.match(/candidates\/search/)),
          constraints: [user.isOnboarded, user.isStriderStaff],
        },
        {
          icon: (
            <Suspense fallback={null}>
              <PassportOutlinedIcon className={iconClassName} />
            </Suspense>
          ),
          activeIcon: (
            <Suspense fallback={null}>
              <PassportIcon className={iconClassName} />
            </Suspense>
          ),
          label: 'My profile',
          to: '/profile',
          isActive: Boolean(location.pathname.match(/profile/)),
          constraints: [user.isOnboarded, user.isSeeker],
        },
        {
          icon: (
            <Suspense fallback={null}>
              <ShareOutlinedIcon className={iconClassName} />
            </Suspense>
          ),
          activeIcon: (
            <Suspense fallback={null}>
              <ShareIcon className={iconClassName} />
            </Suspense>
          ),
          label: 'Refer',
          to: '/refer',
          isActive:
            Boolean(location.pathname.match(/refer/)) ||
            (location.pathname === '/' && !user.isSeeker),
          constraints: [user.isRecruiter || user.isSeeker],
        },
        {
          icon: (
            <Suspense fallback={null}>
              <StarsOutlinedIcon className={iconClassName} />
            </Suspense>
          ),
          activeIcon: (
            <Suspense fallback={null}>
              <StarsIcon className={iconClassName} />
            </Suspense>
          ),
          label: 'Benefits',
          to: '/benefits',
          isActive: Boolean(location.pathname.match(/benefits/)),
          constraints: [user.isOnboarded, user.isSeeker || user.isRecruiter],
        },
        {
          label: 'English Check',
          to: '/english-check/evaluations',
          isActive: Boolean(location.pathname.match(/english-check\/evaluation/)),
          constraints: [user.isEnglishEvaluator],
          hiddenOnMobile: true,
        },
        {
          icon: (
            <Suspense fallback={null}>
              <BriefcaseOutlinedIcon className={iconClassName} />
            </Suspense>
          ),
          activeIcon: (
            <Suspense fallback={null}>
              <BriefcaseIcon className={iconClassName} />
            </Suspense>
          ),
          label: 'Job board',
          to: '/job-board',
          isActive: Boolean(location.pathname.match(/job-board/)),
          constraints: [user.isRecruiter],
        },
        {
          icon: (
            <Suspense fallback={null}>
              <CoinsIcon className={iconClassName} />
            </Suspense>
          ),
          activeIcon: (
            <Suspense fallback={null}>
              <CoinsIcon className={iconClassName} />
            </Suspense>
          ),
          label: 'Referrals',
          to: '/referrals',
          isActive: Boolean(location.pathname.match(/referrals/)),
          constraints: [user.isEmployer],
          isSeen,
          onClick: setSeen,
        },
      ].filter(({ constraints }) => constraints.every((valid) => valid)),
    [user, location],
  )

  return { navigationOptions }
}
