import { useMonitoring } from 'shared/hooks'
import { useNetwork } from 'shared/hooks'
import { useToast } from 'shared/hooks'
import { CertificationFormValues } from 'modules/profiles/domain'
import { UpsertCertificationDTO } from 'modules/profiles/dtos'
import { useMutation, useQueryClient } from 'react-query'

export const useCertification = (profileId: string) => {
  const client = useQueryClient()
  const { post, put, delete: deleteReq } = useNetwork()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()

  const { mutateAsync: deleteCertification, isLoading: isDeleting } = useMutation(
    (certificationId: string) =>
      deleteReq(`profile/${profileId}/certification/${certificationId}`).catch(
        (error: RequestError) => {
          toastError(
            `Error when deleting the certification record: ${error.response?.data.message ?? 'Unknown error'}`,
          )
          captureException(error)
        },
      ),
    {
      onSuccess: () => {
        client.invalidateQueries('profile')
        client.invalidateQueries(`candidates/${profileId}`)
      },
    },
  )

  const { mutateAsync: upsertCertification, isLoading: isUpserting } = useMutation(
    (certification: CertificationFormValues) => {
      if (certification.id) return update(certification)
      return create(certification)
    },
    {
      onSuccess: () => {
        client.invalidateQueries('profile')
        client.invalidateQueries(`candidates/${profileId}`)
      },
      onError: (error: RequestError) => {
        toastError(
          `Error when upserting the certification record: ${error.response?.data.message ?? 'Unknown error'}`,
        )
        captureException(error)
      },
    },
  )

  const create = (certification: CertificationFormValues) => {
    return post(
      `profile/${profileId}/certification`,
      new UpsertCertificationDTO(certification).toJSON(),
    )
  }

  const update = (certification: CertificationFormValues) => {
    return put(
      `profile/${profileId}/certification/${certification.id}`,
      new UpsertCertificationDTO(certification).toJSON(),
    )
  }

  return { upsertCertification, deleteCertification, isUpserting, isDeleting }
}
