import { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { TabContent, Tabs, TabsList, TabTrigger } from 'shared/components/molecules'
import { Spinner } from 'shared/components/atoms'

import { Header } from './components/Header'
import { Overview } from './components/Overview'
import { ReferTalent } from './components/ReferTalent'
import { Handbook } from './components/Handbook'
import { SeekerOnboarding } from './components/SeekerOnboarding'
import { InstructionsModal } from './components/Overview/components/InstructionsModal'

import { PendingSupplementalTermsChecker } from 'pages/PendingSupplementalTermsChecker'
import { SupplementalTermsFeature } from 'modules/supplemental-terms/enums'
import { useRecruiter } from 'hooks/recruiter/useRecruiter'
import { useAuthenticatedUser } from 'shared/hooks'
import { usePageTitle } from 'shared/hooks'
import { useTabs } from './hooks/useTabs'

import { Tab } from './types'
import { MonitoringContext } from 'contexts/monitoring'

export const Refer = () => {
  usePageTitle('Refer')

  const urlParams = new URLSearchParams(window.location.search)
  const prevTab = urlParams.get('tab') as Tab | null

  const { startReplaySession } = useContext(MonitoringContext)

  const navigate = useNavigate()
  const {
    user: { isSeeker, isStriderStaff },
  } = useAuthenticatedUser()

  const { recruiter, isLoading } = useRecruiter()
  const { tabs } = useTabs(isStriderStaff)

  const defaultTab = isStriderStaff ? 'overview' : 'refer'
  const [tab, setTab] = useState<Tab>(prevTab || defaultTab)

  const handleTabSwitch = (tab: Tab) => {
    setTab(tab)
    navigate(`?tab=${tab}`, { replace: true })
  }

  const tabsContent = useMemo<Array<{ tab: Tab; component: ReactNode; enabled: boolean }>>(
    () =>
      [
        {
          tab: 'overview' as Tab,
          component: <Overview recruiter={recruiter} />,
          enabled: isStriderStaff,
        },
        { tab: 'refer' as Tab, component: <ReferTalent />, enabled: true },
        { tab: 'handbook' as Tab, component: <Handbook />, enabled: true },
      ].filter(({ enabled }) => enabled),
    [isStriderStaff, recruiter],
  )

  useEffect(() => {
    startReplaySession()
  }, [])

  if (isLoading) return <Spinner />
  if (!recruiter.id && isSeeker) return <SeekerOnboarding />
  if (!recruiter.id) return null

  if (recruiter.outOfBounds.byCountry || recruiter.outOfBounds.byTaxInformation) {
    navigate('/welcome-payment-out-of-bounds')
    return null
  }

  if (recruiter.outOfBounds.byStriderStaff) {
    navigate('/welcome-application-rejected')
    return null
  }

  if (!recruiter.approved) {
    navigate('/welcome-application-received')
    return null
  }

  return (
    <>
      <PendingSupplementalTermsChecker feature={SupplementalTermsFeature.Refer} />
      <Header slug={recruiter.slug} />
      <Tabs defaultValue={tab}>
        <TabsList>
          {tabs.map(({ tab: componentTab, label }, index) => (
            <TabTrigger
              active={tab === componentTab}
              value={componentTab}
              key={componentTab}
              index={index}
              onClick={() => handleTabSwitch(componentTab as Tab)}
            >
              {label}
            </TabTrigger>
          ))}
        </TabsList>
        {tabsContent.map(({ tab, component }) => (
          <TabContent key={tab} value={tab} className="mt-6">
            {component}
          </TabContent>
        ))}
      </Tabs>
      <InstructionsModal recruiter={recruiter} />
    </>
  )
}
