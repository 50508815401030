import { useQuery } from 'react-query'

import { useAuthenticatedUser, useMonitoring, useNetwork, useToast } from 'shared/hooks'

import { FetchPreferencesDTO, FetchPreferencesResponse } from 'modules/profiles/dtos'
import { nullPreferences } from '.'

export const useFetchPreferences = (profileId: string) => {
  const {
    user: { isSeeker, isJobSeekerProfilesManager },
  } = useAuthenticatedUser()
  const { get } = useNetwork()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()

  const {
    data: preferences = nullPreferences,
    isLoading,
    isRefetching,
  } = useQuery(
    `${profileId}-preferences`,
    async () => {
      if (!isSeeker && !isJobSeekerProfilesManager) return
      const { data } = await get<FetchPreferencesResponse>(`/profile/${profileId}/preferences`)
      return new FetchPreferencesDTO(data).toDomain()
    },
    {
      enabled: Boolean(profileId),
      staleTime: 5 * 60 * 1000,
      onError: (err) => {
        toastError('Could not fetch your preferences.')
        captureException(err)
      },
    },
  )

  return { preferences, isLoading: isLoading || isRefetching }
}
