import { TrackingEvent } from '../tracking-event'

export class MatchingFinalInterviewRequested implements TrackingEvent {
  name = 'Matching final interview requested'

  constructor(
    private readonly matchingId: string,
    private readonly jobListingId: string,
  ) {}

  get payload() {
    return { matchingId: this.matchingId, jobListingId: this.jobListingId }
  }
}
