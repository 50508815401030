import { TrackingEvent } from '../tracking-event'

export class HireButtonClick implements TrackingEvent {
  name = 'Hire button click'

  constructor(private readonly profileId: string) {}

  get payload() {
    return { profileId: this.profileId }
  }
}
