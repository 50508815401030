import { useState } from 'react'
import * as Dialog from '@radix-ui/react-dialog'

import { cn } from 'modules/shared'
import { Text } from 'shared/components/atoms'
import { AlertDialog } from 'shared/components/molecules'

import { ModalHeader } from './components'

interface ModalProps {
  title?: string | JSX.Element
  content?: React.ReactElement
  children?: React.ReactElement
  footer?: React.ReactElement
  showClose?: boolean
  showDismissalConfirmation?: boolean
  handleClose?(): void
  open?: boolean
  shouldGrow?: boolean
  withBorders?: boolean
  titleClassName?: string
  titleContainerClassName?: string
  contentContainerClassName?: string
  containerClassName?: string
  footerContainerClassName?: string
  mobilePositioning?: 'fullscreen' | 'bottom'
  contentRef?: React.RefObject<HTMLDivElement>
}

const Modal = ({
  title,
  content,
  children,
  footer,
  showClose = true,
  showDismissalConfirmation = false,
  handleClose,
  open = true,
  shouldGrow = true,
  withBorders = true,
  titleClassName,
  titleContainerClassName,
  contentContainerClassName,
  containerClassName,
  footerContainerClassName,
  mobilePositioning = 'fullscreen',
  contentRef,
}: ModalProps) => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)

  const handleOpenChange = (open: boolean) => {
    if (open || !handleClose) return
    if (showDismissalConfirmation) return showConfirmation()
    handleClose()
  }

  const showConfirmation = () => setShowConfirmationDialog(true)
  const handleAlertCancel = () => setShowConfirmationDialog(false)

  if (showConfirmationDialog) {
    return (
      <AlertDialog
        isOpen={showConfirmationDialog}
        title="Closing confirmation"
        description={
          <Text size="text-base" weight="font-normal">
            Are you sure you want to close this modal? Unsaved changes will be lost
          </Text>
        }
        onConfirmClick={handleClose}
        onCancelClick={handleAlertCancel}
        confirmText="Leave without saving"
        cancelText="Go back"
      />
    )
  }

  return (
    <Dialog.Root open={open} onOpenChange={handleOpenChange}>
      <Dialog.Portal>
        {/* the following z-index should be z-40 because Overlay component */}
        <Dialog.Overlay className="fixed inset-0 bg-black/40 z-40" />
        <Dialog.Content
          onPointerDownOutside={(event) => {
            const target = event.target as HTMLElement

            if (target?.hasAttribute('data-grammarly-shadow-root')) {
              event.preventDefault()
              return
            }

            const targetClasses = target?.className || target?.parentElement?.className || ''

            if (['pac-container pac-logo', 'pac-matched', 'pac-item'].includes(targetClasses))
              event.preventDefault()
          }}
          className={cn(
            'fixed left-1/2 -translate-x-1/2 z-40',
            'w-full sm:max-w-[640px] sm:h-auto',
            'bg-white rounded-lg',
            'flex flex-col',
            {
              'sm:max-h-[88%]': shouldGrow,
              'sm:max-h-[644px]': !shouldGrow,
              'h-full top-1/2 -translate-y-1/2': mobilePositioning === 'fullscreen',
              'h-fit bottom-0 max-h-full sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2':
                mobilePositioning === 'bottom',
            },
            containerClassName,
          )}
        >
          <ModalHeader
            title={title}
            showClose={showClose}
            showDismissalConfirmation={showDismissalConfirmation}
            withBorders={withBorders}
            titleClassName={titleClassName}
            titleContainerClassName={titleContainerClassName}
            showConfirmation={showConfirmation}
            handleClose={handleClose}
          />

          <div
            ref={contentRef}
            className={cn(
              'flex-grow overflow-auto px-5 py-3 sm:px-10 sm:py-6',
              contentContainerClassName,
            )}
          >
            {content || children}
          </div>
          {Boolean(footer) && (
            <div
              className={cn(
                'flex flex-col-reverse md:flex-row items-center justify-end gap-4 ',
                {
                  'border-t border-neutral-lighter': withBorders,
                  'px-5 py-3 sm:px-10 sm:py-6': withBorders,
                  'px-5 pb-3 sm:px-10 sm:pb-6': !withBorders,
                },
                footerContainerClassName,
              )}
            >
              {footer}
            </div>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export { Modal }
