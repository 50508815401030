import { useMutation, useQueryClient } from 'react-query'

import { UpdateScheduleLinksDTO, UpdateScheduleLinksValues } from 'matching/dtos'
import { useMonitoring, useNetwork, useToast } from 'shared/hooks'

export const useUpdateScheduleLinks = ({ jobListingId }: { jobListingId: string }) => {
  const { patch } = useNetwork()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()
  const client = useQueryClient()

  const { mutateAsync: updateScheduleLinks, isLoading } = useMutation(
    (payload: UpdateScheduleLinksValues) =>
      patch(
        `/listings/${jobListingId}/schedule-links`,
        new UpdateScheduleLinksDTO(payload).toJSON(),
      ),
    {
      onSuccess: () => {
        client.invalidateQueries(['listings', jobListingId, 'preview'])
      },
      onError: (error: RequestError) => {
        toastError(
          `Could not update the schedule links: ${error.response?.data.message ?? 'Unknown error'}`,
        )
        captureException(error)
      },
    },
  )

  return { updateScheduleLinks, isLoading }
}
