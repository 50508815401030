import { UpdateProfileBioSchema } from 'profile/domain'
import { AnySchema, object, string } from 'yup'

export { Bio } from './Bio'

export const MAX_BIO_TEXT_LENGTH = 1000

export const schema = object().shape<Record<keyof UpdateProfileBioSchema, AnySchema>>({
  bioText: string().trim().max(MAX_BIO_TEXT_LENGTH).min(1, 'Required field.'),
})
