import { lazy, Suspense, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Spinner } from 'shared/components/atoms'
import { Warning } from 'shared/components/molecules'
import { PrimaryButton } from 'shared/components/atoms'

import { ProfileOverviewSection } from 'pages/JobSeekerProfile/components/ProfileOverviewSection'
import { ProfileEducationSection } from 'pages/JobSeekerProfile/components/ProfileEducationSection'
import { ProfileWorkExperienceSection } from 'pages/JobSeekerProfile/components/ProfileWorkExperienceSection'
import { ProfileCertificateSection } from 'pages/JobSeekerProfile/components/ProfileCertificateSection'
import { PreferencesSection } from 'pages/JobSeekerProfile/components/PreferencesSection'

import { Sidebar } from 'pages/JobSeekerProfile/components/Sidebar'
import { EditModalDataType, Modal } from 'pages/JobSeekerProfile/components/EditModals'
import { ProfileViewTracker } from 'pages/JobSeekerProfile/components/ProfileViewTracker'

import { useAuthenticatedUser } from 'shared/hooks'
import { useFetchPreferences, useCandidatePageTitle, useTasks } from 'pages/JobSeekerProfile/hooks'

import { CandidateContext } from 'contexts/candidate'
import { LayoutContext } from 'contexts/layout'
import { ProfileUpdatedBanner } from 'pages/JobSeekerProfile/components/ProfileUpdatedBanner'

import { ProfileEducation, ProfileCertification } from 'pages/JobSeekerProfile/types'
import { WorkExperience } from 'modules/work-experience'
import { PayRateSection } from 'pages/JobSeekerProfile/components/PayRateSection'
import { useBrowserURL } from 'shared/hooks'
import { EDIT_PAY_RATE_PATH } from 'pages/JobSeekerProfile/components/EditModals/PayRate'
import { DeactivatedAccountBanner } from 'components/molecules/DeactivatedAccountBanner'
import { WelcomeBackModal } from 'components/molecules/WelcomeBackModal'
import { useProfileAvailability } from 'hooks/profile/useProfileAvailability'
import { Tasks } from 'modules/candidates/enums'

const EditModals = lazy(() =>
  import('pages/JobSeekerProfile/components/EditModals/EditModals').then((module) => ({
    default: module.EditModals,
  })),
)

export const JobSeekerProfileViewPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { replaceCurrentURL } = useBrowserURL()

  const {
    candidate: profile,
    isLoading: isProfileLoading,
    isReloading: isRefetching,
    loadCandidate,
  } = useContext(CandidateContext)
  const {
    setRootHaveLimitedSize,
    setDefaultContainer,
    setRootPadding,
    setHideNavbar,
    setHideBanner,
  } = useContext(LayoutContext)

  const { isLoaded } = useAuthenticatedUser()
  const { preferences } = useFetchPreferences(profile.id)
  const { isUnavailable, isPaused } = useProfileAvailability(preferences.availability)

  useCandidatePageTitle(profile)

  const { tasks, acknowledgeUpdates } = useTasks(profile.id)
  const showProfileUpdateBanner = tasks.includes(Tasks.AcknowledgeProfileChanges)

  useEffect(() => {
    loadCandidate(id)
    setRootHaveLimitedSize(false)
    setRootPadding(true)
    setHideNavbar(Boolean(id))
    setHideBanner(Boolean(id))

    return () => setDefaultContainer()
  }, [id])

  useEffect(() => {
    const path = window.location.pathname

    if (path === EDIT_PAY_RATE_PATH) {
      showModal(Modal.PayRate)
      replaceCurrentURL('/profile/pay-rate/edit')
    }
  }, [])

  const [openedModal, setOpenedModal] = useState<Modal>(Modal.None)
  const [editModalData, setEditModalData] = useState<EditModalDataType>()
  const [isWelcomeBackModalOpen, setIsWelcomeBackModalOpen] = useState(false)

  const openWelcomeBackModal = () => setIsWelcomeBackModalOpen(true)
  const closeWelcomeBackModal = () => setIsWelcomeBackModalOpen(false)

  const handleDoneEditing = () => navigate(`/candidates/${id}`)

  const showModal = (modal: Modal) => setOpenedModal(modal)
  const closeModal = () => {
    setOpenedModal(Modal.None)
    setEditModalData(undefined)
  }

  const handleEditWorkExperience = (workExperience: WorkExperience) => {
    setEditModalData(workExperience)
    showModal(Modal.AddWorkExperience)
  }

  const handleEditEducation = (education: ProfileEducation) => {
    setEditModalData(education)
    showModal(Modal.EditEducation)
  }

  const handleEditCertification = (certification: ProfileCertification) => {
    setEditModalData(certification)
    showModal(Modal.EditCertification)
  }

  if (!isLoaded || (isProfileLoading && !isRefetching)) return <Spinner />

  return (
    <div className="flex flex-col container self-center items-center md:mt-16">
      <div className="w-full flex flex-col items-start">
        <div className="flex flex-col lg:flex-row gap-16 lg:gap-16 xl:gap-28 w-full justify-center">
          <Sidebar
            profile={profile}
            onAboutEdit={() => showModal(Modal.About)}
            onAddWorkExperienceClick={() => showModal(Modal.AddWorkExperience)}
            onAddEducationClick={() => showModal(Modal.EditEducation)}
            onEditBio={() => showModal(Modal.Bio)}
            onPreferencesClick={() => showModal(Modal.Preferences)}
            onEditRolesAndSkillsClick={() => showModal(Modal.RolesAndSkills)}
            onPayRateClick={() => showModal(Modal.PayRate)}
            preferences={preferences}
          />
          <div className="w-full max-w-[680px] xl:max-w-[744px] flex flex-col">
            {Boolean(id) && (
              <div className="w-full flex flex-row items-center gap-10 mb-6">
                <Warning className="flex-grow !p-4">
                  Any changes made will be visible to both {profile.firstName} and companies that
                  view this profile.
                </Warning>
                <PrimaryButton onClick={handleDoneEditing}>Done editing</PrimaryButton>
              </div>
            )}
            {isPaused && (
              <div className="mb-16">
                <DeactivatedAccountBanner
                  profileId={profile.id}
                  preferences={preferences}
                  onUpdate={openWelcomeBackModal}
                />
              </div>
            )}
            {showProfileUpdateBanner && <ProfileUpdatedBanner onDimiss={acknowledgeUpdates} />}
            <ProfileOverviewSection
              readOnly={false}
              profile={profile}
              onEdit={() => showModal(Modal.RolesAndSkills)}
              onBioEdit={() => showModal(Modal.Bio)}
              showAssessmentTags={Boolean(id)}
              isUnavailable={isUnavailable}
            />
            <PayRateSection
              preferences={preferences}
              readOnly={false}
              onEdit={() => showModal(Modal.PayRate)}
            />
            <ProfileWorkExperienceSection
              profileId={profile.id}
              readOnly={false}
              experiences={profile.experiencesHistory}
              groupedExperiences={profile.groupedExperiences}
              onAddWorkExperienceClick={() => showModal(Modal.AddWorkExperience)}
              onEditWorkExperienceClick={handleEditWorkExperience}
            />
            <ProfileEducationSection
              readOnly={false}
              educations={profile.educationHistory}
              onAddEducationClick={() => showModal(Modal.EditEducation)}
              onEditEducationClick={handleEditEducation}
            />
            <ProfileCertificateSection
              readOnly={false}
              certifications={profile.certifications}
              onAddCertificationClick={() => showModal(Modal.EditCertification)}
              onEditCertificationClick={handleEditCertification}
            />
            <PreferencesSection
              preferences={preferences}
              fulfillment={profile.fulfillment}
              readOnly={false}
              onEdit={() => showModal(Modal.Preferences)}
            />
          </div>
        </div>

        <Suspense fallback={null}>
          <EditModals
            modal={openedModal}
            profile={profile}
            preferences={preferences}
            modalData={editModalData}
            onCloseModal={closeModal}
          />
        </Suspense>
        <WelcomeBackModal isOpen={isWelcomeBackModalOpen} onClose={closeWelcomeBackModal} />
      </div>
      <ProfileViewTracker profile={profile} />
    </div>
  )
}
