import { useMutation, useQueryClient } from 'react-query'

import { useAuthenticatedUser, useMonitoring, useNetwork, useToast } from 'shared/hooks'

export const useInstitution = (profileId: string) => {
  const client = useQueryClient()

  const {
    user: { isJobSeekerProfilesManager },
  } = useAuthenticatedUser()
  const { post } = useNetwork()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()

  const { mutateAsync: publishInstitution } = useMutation(
    (institutionId: number) => post(`profile/${profileId}/institutions/${institutionId}/publish`),
    {
      onSuccess: () => client.invalidateQueries('institution'),
      onError: (error: RequestError) => {
        toastError('An error ocurred when trying to publish the institution.')
        captureException(error)
      },
    },
  )

  const { mutateAsync: createInstitution, isLoading } = useMutation(
    (data: string) =>
      post<{ id: number; label: string }>(`profile/${profileId}/institutions`, {
        label: data,
      }),
    {
      onSuccess: async ({ data }) => {
        if (isJobSeekerProfilesManager) await publishInstitution(data.id)
        client.invalidateQueries('institutions')
      },
      onError: (error: RequestError) => {
        toastError(
          `Error when upserting the institution record: ${error.response?.data.message ?? 'Unknown error'}`,
        )
        captureException(error)
      },
    },
  )

  return { createInstitution, publishInstitution, isLoading }
}
