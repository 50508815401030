import { useContext } from 'react'
import { useMutation } from 'react-query'

import { useToast, useNetwork } from 'shared/hooks'

import { useUpdateMatching, useUpdateMatchingSettings } from 'hooks/matchings'
import { useUpdateSearchInfo } from '..'

import { SearchContext } from 'candidates-search/contexts'

import { MatchingStatus, matchingSuccessMessages } from 'matching/enums'
import { useMonitoring } from 'shared/hooks/monitoring'
import { Stack } from 'modules/stack'

interface CreateMatchingProps {
  status: MatchingStatus
  compensation?: number
  opportunityCompensation?: number
  profileImprovements?: string
}

interface UpdateMatchingProps {
  status: MatchingStatus
  compensation?: number
  fee?: number
  opportunityCompensation?: number
  profileImprovements?: string
  briefing: string
  highlightedSkills: Array<Stack>
}

export const useUpsertMatching = (candidateId: string) => {
  const { search, setSearchResult } = useContext(SearchContext)
  const { updateSearchInfo } = useUpdateSearchInfo()
  const { captureException } = useMonitoring()

  const { post } = useNetwork()
  const { toastSuccess, toastError } = useToast()

  const matchingId = search?.getMatchingByProfileId(candidateId)?.matchingId
  const jobListingId = search?.listing?.id

  const { update } = useUpdateMatching(jobListingId, matchingId)
  const { updateMatchingSettings, isUpdatingSettings } = useUpdateMatchingSettings({
    listingId: jobListingId,
    profileId: candidateId,
    matchingId,
  })

  const { mutateAsync: createMatching, isLoading: isCreating } = useMutation(
    ({
      status,
      compensation,
      opportunityCompensation,
      profileImprovements,
    }: CreateMatchingProps & { preventResultListUpdate?: boolean }) =>
      post(`/listings/${jobListingId}/matchings`, {
        status,
        candidateId,
        compensation,
        profileImprovements,
        opportunityCompensation: opportunityCompensation
          ? Number(opportunityCompensation)
          : undefined,
      }),
    {
      onSuccess: (_, { status, preventResultListUpdate }) => {
        if (!preventResultListUpdate) updateResultList()
        updateSearchInfo(search?.id)
        if (matchingSuccessMessages[status]) toastSuccess(matchingSuccessMessages[status])
      },
      onError: (error: RequestError) => {
        toastError(error.response?.data.message)
        captureException(error)
      },
    },
  )

  const upsertMatching = async (
    payload: (CreateMatchingProps | UpdateMatchingProps) & { preventResultListUpdate?: boolean },
  ) => {
    if (!matchingId) return createMatching(payload as CreateMatchingProps)

    const {
      compensation,
      fee,
      status,
      opportunityCompensation,
      profileImprovements,
      briefing,
      highlightedSkills,
    } = payload as UpdateMatchingProps

    if (compensation && fee) {
      await updateMatchingSettings({
        compensation: Number(compensation),
        fee: Number(fee),
        briefing,
        highlightedSkills,
        preventSuccessToast: true,
      })
    }

    await update({
      status,
      bookmarked: false,
      opportunityCompensation,
      profileImprovements,
      showSuccessMessage: true,
    }).then(() => {
      if (!payload.preventResultListUpdate) updateResultList()
      updateSearchInfo(search?.id)
    })
  }

  const updateResultList = () => {
    setSearchResult((previous) => ({
      profiles: previous.profiles.filter(({ id }) => id !== candidateId),
      totalRecords: previous.totalRecords - 1,
      totalPages: previous.totalPages,
    }))
  }

  return { upsertMatching, updateResultList, isUpserting: isCreating || isUpdatingSettings }
}
