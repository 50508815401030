import { cn } from 'modules/shared'
import { Paragraph } from 'shared/components/atoms'

interface IconBannerProps {
  children: string | JSX.Element
  icon?: JSX.Element
  bgColor?:
    | 'bg-success-lighter'
    | 'bg-danger-lighter'
    | 'bg-info-lighter'
    | 'bg-warning-lighter'
    | 'bg-neutral-lightest'
  border?: 'border-warning-light'
  className?: string
}

export const IconBanner = ({
  children,
  icon,
  bgColor = 'bg-neutral-lightest',
  border,
  className,
}: IconBannerProps) => {
  return (
    <div
      className={cn(
        'flex gap-3 items-start p-4 rounded-lg',
        bgColor,
        border,
        { border: Boolean(border) },
        className,
      )}
    >
      {icon}
      <Paragraph size="body-sm" weight="font-normal" className="text-neutral-darkest">
        {children}
      </Paragraph>
    </div>
  )
}
