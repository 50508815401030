import { useEffect } from 'react'
import { cn } from 'modules/shared'

import * as Dialog from '@radix-ui/react-dialog'
import { TertiaryButton } from 'shared/components/atoms'
import { Title } from 'shared/components/atoms'

import { ReactComponent as CloseIcon } from 'icons/close.svg'

interface Props {
  children: React.ReactNode
  position: 'right' | 'left'
  title?: string
  customTitle?: (({ onClose }: { onClose: () => void }) => React.ReactNode) | React.ReactNode
  isOpen: boolean
  handleClose: () => void
  cta?: (({ onClose }: { onClose: () => void }) => React.ReactNode) | React.ReactNode
  closeIcon?: React.ReactElement
  contentContainerClassName?: string
  ctaContainerClassName?: string
}

export const Drawer = ({
  children,
  position,
  title,
  customTitle,
  cta,
  isOpen,
  handleClose,
  closeIcon,
  contentContainerClassName,
  ctaContainerClassName,
}: Props) => {
  const handleOpenChange = (open: boolean) => !open && onClose()

  const onClose = () => {
    const container = document.getElementById('drawer-content-container')
    if (!container) return
    container.classList.remove('translate-x-0')
    container.classList.add('translate-x-full')

    setTimeout(handleClose, 300)
  }

  const titleContent = typeof customTitle === 'function' ? customTitle({ onClose }) : customTitle

  useEffect(() => {
    const container = document.getElementById('drawer-content-container')
    if (!container) return
    container.classList.toggle('translate-x-full', !isOpen)
    container.classList.toggle('translate-x-0', isOpen)
  }, [isOpen])

  return (
    <Dialog.Root open={isOpen} onOpenChange={handleOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/40 z-20" onClick={onClose} />
        <Dialog.Content
          id="drawer-content-container"
          className={cn(
            'fixed top-0 z-30 flex flex-col h-full w-fit bg-neutral-day transition-transform duration-300 translate-x-full',
            {
              'right-0': position === 'right',
              'left-0': position === 'left',
            },
            contentContainerClassName,
          )}
        >
          {customTitle ? (
            titleContent
          ) : (
            <div className="flex items-center gap-4 pb-5 pt-6 px-10">
              <TertiaryButton
                size="md"
                className="focus:shadow-none"
                icon={closeIcon || <CloseIcon className="!w-6 !h-6" />}
                onClick={onClose}
              />
              <Title size="heading">{title}</Title>
            </div>
          )}
          <div id="drawer-children-container" className="flex-grow overflow-y-scroll">
            {children}
          </div>
          {Boolean(cta) && (
            <div
              className={cn(
                'flex gap-4 px-10 py-6 border-t border-neutral-light',
                ctaContainerClassName,
              )}
            >
              {typeof cta === 'function' ? cta({ onClose }) : cta}
            </div>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
