import { useState } from 'react'

import { PrimaryButton, SecondaryButton, TertiaryButton, Text } from 'shared/components/atoms'
import { AlertDialog } from 'shared/components/molecules'

import { useUpsertMatching } from 'candidates-search/hooks'
import { MatchingSimulation } from 'hooks/matchings'
import { FormControl } from 'shared/hooks'

import { MatchingStatus } from 'matching/enums'
import { MatchingSettingsFormSchema } from 'matching/domain'

interface DrawerCTAProps {
  candidateId: string
  simulation: MatchingSimulation | null
  isLoadingSimulation: boolean
  isMissingParams: boolean
  formControl: FormControl
  onBack: () => void
}

export const DrawerCTA = ({
  candidateId,
  simulation,
  isLoadingSimulation,
  formControl,
  isMissingParams,
  onBack,
}: DrawerCTAProps) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const { upsertMatching, isUpserting } = useUpsertMatching(candidateId)

  const { isValid, getValues } = formControl

  const disableMatching =
    isMissingParams ||
    !isValid ||
    isLoadingSimulation ||
    !simulation ||
    Boolean(simulation?.conflict)

  const handleCreate = () => {
    setShowConfirmationModal(false)

    const values = getValues() as MatchingSettingsFormSchema
    upsertMatching({
      status: MatchingStatus.Matched,
      compensation: values.compensation,
      fee: simulation?.fee,
      briefing: values.briefing,
      highlightedSkills: values.highlightedSkills,
    }).then(() => onBack())
  }

  const handleButtonClick = () => {
    if (simulation?.withinBudget) {
      return handleCreate()
    }

    setShowConfirmationModal(true)
  }

  const handleCancel = () => setShowConfirmationModal(false)

  return (
    <>
      <div className="flex flex-row justify-end w-full gap-4">
        <TertiaryButton onClick={onBack}>Back</TertiaryButton>
        {simulation?.withinBudget ? (
          <PrimaryButton
            onClick={handleButtonClick}
            disabled={disableMatching}
            isLoading={isUpserting}
          >
            Match candidate
          </PrimaryButton>
        ) : (
          <SecondaryButton
            onClick={handleButtonClick}
            disabled={disableMatching}
            isLoading={isUpserting}
          >
            Match anyway
          </SecondaryButton>
        )}
      </div>

      <AlertDialog
        isOpen={showConfirmationModal}
        title="Confirm matching creation"
        description={
          <Text size="text-base" weight="font-normal">
            You're about to create a matching for a profile whose cost is above the selected job
            listing budget.
          </Text>
        }
        confirmText="I understand, create it anyway"
        onConfirmClick={handleCreate}
        onCancelClick={handleCancel}
      />
    </>
  )
}
