import { useEffect, useState } from 'react'

import { Checkbox, Label, Paragraph, Text } from 'shared/components/atoms'
import { FormControl, useAuthenticatedUser } from 'shared/hooks'
import { NumericExpectedAnswer, QuestionSchema } from 'screening/domain'
import { BooleanExpectedAnswer, QuestionType } from 'screening/enums'
import { SavedAnswer } from 'job-opportunity/exports'

import { BooleanQuestion } from './BooleanQuestion'
import { ShortTextQuestion } from './ShortTextQuestion'
import { LongTextQuestion } from './LongTextQuestion'
import { WorkExperiences } from './WorkExperiences'
import { NumericQuestion } from './NumericQuestion'

interface Props {
  index: number
  question: QuestionSchema
  name: string
  formControl: FormControl
  persist: (value: SavedAnswer) => void
  isLoading?: boolean
  isPreview?: boolean
}

export const QuestionCard = ({
  index,
  question,
  name,
  formControl,
  persist,
  isLoading,
  isPreview,
}: Props) => {
  const {
    user: { isStriderStaff },
  } = useAuthenticatedUser()
  const { watch, getError, register, setValue } = formControl

  const noExperienceName = `${name}.noExperience`
  const noExperience = watch(noExperienceName)

  const valueName = `${name}.value`
  const answer = watch(valueName)

  const error = (getError('answers') ?? [])[index]
  const valueError = error?.value

  const workExperiencesName = `${name}.workExperiences`
  const workExperiences = watch(workExperiencesName)

  useEffect(() => {
    if (isLoading) return

    persist({
      index: name,
      workExperiences,
      value: answer || null,
      noExperience: noExperience,
    })
  }, [answer, workExperiences])

  const expectedAnswer = (() => {
    if (!isStriderStaff || !isPreview) return
    return question.expectedAnswer
  })()

  const [isNotApplicableSelected, setIsNotApplicableSelected] =
    useState<Optional<boolean>>(noExperience)
  const handleChangeSelection = () => setIsNotApplicableSelected((value) => !value)

  useEffect(() => {
    if (isNotApplicableSelected) {
      setValue(valueName, 0)
      return
    }
    const answer = watch(valueName)
    setValue(valueName, answer)
  }, [isNotApplicableSelected])

  const componentStrategies = {
    [QuestionType.Boolean]: (
      <BooleanQuestion
        name={name}
        formControl={formControl}
        disabled={isPreview}
        expectedAnswer={expectedAnswer as Nullable<BooleanExpectedAnswer>}
        showWarning={Boolean(valueError)}
      />
    ),
    [QuestionType.ShortText]: (
      <ShortTextQuestion
        name={name}
        formControl={formControl}
        disabled={isPreview}
        isOptional={!question.required}
        error={valueError}
      />
    ),
    [QuestionType.LongText]: (
      <LongTextQuestion
        name={name}
        formControl={formControl}
        disabled={isPreview}
        isOptional={!question.required}
        error={valueError}
      />
    ),
    [QuestionType.Numeric]: (
      <NumericQuestion
        name={name}
        formControl={formControl}
        expectedAnswer={expectedAnswer as Nullable<NumericExpectedAnswer>}
        disabled={isPreview || isNotApplicableSelected}
        showWarning={Boolean(valueError)}
      />
    ),
  }

  return (
    <div className="mt-14 flex gap-x-4" aria-labelledby={`question-${index}`}>
      <div className="flex items-center justify-center bg-neutral-lightest rounded-lg w-8 h-8">
        <Text>{index + 1}</Text>
      </div>
      <div className="w-full">
        <Paragraph id={`question-${index}`} size="body-lg" weight="font-medium">
          {question.title}
          {Boolean(question.required) && '*'}
        </Paragraph>
        <Text size="text-sm" className="text-neutral-dark">
          {question.description}
        </Text>
        <div className="mt-8">{componentStrategies[question.type]}</div>
        <WorkExperiences
          formControl={formControl}
          name={name}
          isOptional={!question.workExperienceRequired}
          disabled={Boolean(isPreview)}
          questionType={question.type}
          error={error?.workExperiences}
        />
        {Boolean(question.type === QuestionType.Numeric) && (
          <div className="flex mt-6 items-center">
            <Checkbox
              id={`${name}.noExperience`}
              name={`${name}.noExperience`}
              register={register}
              checked={isNotApplicableSelected}
              onClick={handleChangeSelection}
            />
            <Label htmlFor={`${name}.noExperience`} className="m-0" weight="font-normal">
              This question doesn't apply to my work experience.
            </Label>
          </div>
        )}
      </div>
    </div>
  )
}
