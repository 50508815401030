import { useAuthenticatedUser } from 'shared/hooks'

export const StriderUsersOnly = ({ children }: { children: React.ReactNode }) => {
  const {
    user: { isStriderStaff },
  } = useAuthenticatedUser()

  if (isStriderStaff) return <>{children}</>

  return null
}
