import { useContext, useEffect, useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { plainToInstance } from 'class-transformer'

import { useMonitoring, useNetwork } from 'shared/hooks'
import { useToast } from 'shared/hooks'

import { SearchContext } from 'candidates-search/contexts'

import { Search, SearchResult } from 'candidates-search/domain'
import {
  FetchSearchResultDTO,
  FetchSearchResultResponse,
  UpdateSearchDTO,
  UpdateSearchResponse,
} from 'candidates-search/dtos'

import { useTabs } from '../content'

export const useSearchResult = (targetProfileId: string | null) => {
  const client = useQueryClient()
  const { post, patch } = useNetwork()
  const { toastError } = useToast()
  const {
    isInitializing,
    search,
    searchResult,
    filter,
    sort,
    currentPage,
    tab,
    applyTabFilter,
    setSearch,
    setSearchResult,
    setLastFilter,
    setCurrentPage,
  } = useContext(SearchContext)
  const { tabItems } = useTabs()

  const { captureException } = useMonitoring()
  const activeTabItems = useMemo(() => tabItems[tab], [tab, tabItems])

  const { refetch, isLoading, isRefetching } = useQuery(
    'advanced-search',
    ({ signal }) => {
      const payload = new FetchSearchResultDTO({
        filter,
        sort,
        page: currentPage,
        listing: search?.listing,
        activeTab: tab,
        activeTabItems: activeTabItems,
        applyTabFilter,
        targetProfileId,
      })
      return post<FetchSearchResultResponse>('advanced-search', payload.toJSON(), { signal })
        .then(({ data }) => {
          setSearchResult(plainToInstance(SearchResult, data))
          if (data.totalRecords) setLastFilter(filter)
        })
        .catch((error) => {
          if (error.message === 'canceled') return
          if (error.response?.status == 422) return

          toastError(
            "Error while searching for candidates. Please, contact Strider's engineering team.",
          )

          captureException(error)
        })
    },
    { enabled: false, retry: false },
  )

  const { mutateAsync: updateFilters, isLoading: isUpdateLoading } = useMutation(
    ({ signal }: { signal: AbortSignal }) => {
      return patch<UpdateSearchResponse>(
        `advanced-search/${search!.id}`,
        new UpdateSearchDTO({
          filter,
          sort,
          page: currentPage,
          listing: search?.listing,
          activeTab: tab,
          activeTabItems: activeTabItems,
          applyTabFilter,
          targetProfileId,
        }).toJSON(),
        { signal },
      )
        .then(({ data }) => {
          setSearchResult(plainToInstance(SearchResult, data.searchResult))
          setSearch(plainToInstance(Search, data.search))
          if (data.searchResult.totalRecords) setLastFilter(filter)
        })
        .catch((error: RequestError) => {
          if (error.message === 'canceled') return
          if (error.response?.status === 422) return

          toastError(
            `Error when updating the search: ${error.response?.data.message ?? 'Unknown error'}`,
          )
          captureException(error)
        })
    },
  )

  useEffect(() => {
    const abortController = new AbortController()

    if (isInitializing) return
    if (!search) {
      return setSearchResult({
        profiles: [],
        totalPages: 0,
        totalRecords: 0,
      })
    }

    if (search?.id) updateFilters(abortController)
    else refetch()

    return () => {
      abortController.abort()
      client.cancelQueries('advanced-search')
    }
  }, [
    isInitializing,
    search?.id,
    search?.isListingLinked,
    filter.url,
    sort,
    currentPage,
    tab,
    applyTabFilter,
  ])

  useEffect(() => setCurrentPage(1), [tab])

  return {
    searchResult,
    isLoading: isLoading || isRefetching || isUpdateLoading || isInitializing,
  }
}
