import { useState } from 'react'

import { cn } from 'modules/shared'
import { ClaimProtected, Tags, Title, Text } from 'shared/components/atoms'
import { Collapse } from 'shared/components/molecules'
import { AccountClaim } from 'shared/hooks'
import { ListingStatusBadge, TalentReviewBadge } from 'job-listing/components'
import { ListingStatus, JobListingStackPreferences } from 'job-listing/enums'
import { ListingInfo } from 'job-listing/domains'
import {
  DraftStatusBanner,
  EditListingButton,
  ListingManagementMenu,
  ListingTermsPreview,
  Subscribers,
} from './components'

interface JobListingPreviewProps {
  listing: ListingInfo
  readOnly?: boolean
  initiallyExpanded?: boolean
  showDraftBanner?: boolean
}

export const JobListingPreview = ({
  listing,
  readOnly = false,
  initiallyExpanded = false,
  showDraftBanner = true,
}: JobListingPreviewProps) => {
  const isEditable = !readOnly
  const [isCollapseExpanded, setCollapseExpanded] = useState(initiallyExpanded)
  const handleCollapseClick = (isCollapseExpanded: boolean) =>
    setCollapseExpanded(isCollapseExpanded)

  const requiredStacks =
    listing.stacks?.filter(
      ({ preference }) => preference === JobListingStackPreferences.Required,
    ) || []
  const tags = requiredStacks.map(({ stack: { id, label } }) => ({
    id,
    label: String(label),
  }))
  const { detailedRequirements, projectDescription } = listing
  const disabledDesktopCollapsing = !detailedRequirements && !projectDescription

  return (
    <div
      aria-labelledby="listing-title"
      className={cn(
        'flex gap-6 flex-col',
        'border-y border-neutral-lighter sm:rounded-lg sm:border sm:border-neutral-light',
        'w-[calc(100%+48px)] -ml-6 p-6 sm:w-full sm:m-0 sm:px-8 sm:pt-8 sm:pb-4',
      )}
    >
      <div className="flex flex-col gap-2 items-stretch justify-start sm:hidden">
        <div className="flex items-center justify-between">
          <Title size="large-heading">{listing.title}</Title>
          {isEditable && (
            <>
              <Subscribers listing={listing} />
              <EditListingButton listingId={listing.id} />
            </>
          )}
          <ListingManagementMenu listing={listing} readOnly={readOnly} />
        </div>
        <div className="flex gap-2">
          <ListingStatusBadge status={listing.status!} />
          <TalentReviewBadge isTalentReview={listing.isTalentReview} />
        </div>
      </div>

      <div className="hidden sm:flex justify-between items-center gap-2">
        <div className="flex-grow flex gap-4 items-center">
          <Title size="large-heading" id="listing-title">
            {listing.title}
          </Title>
          <ListingStatusBadge status={listing.status!} />
          <TalentReviewBadge isTalentReview={listing.isTalentReview} />
          {isEditable && <Subscribers listing={listing} />}
        </div>

        {isEditable && <EditListingButton listingId={listing.id} />}
        <ListingManagementMenu listing={listing} readOnly={readOnly} />
      </div>

      <div className="flex gap-8 lg:gap-16 flex-wrap">
        <div
          className={cn('flex-col gap-2 order-1', {
            flex: isCollapseExpanded,
            'hidden sm:flex': !isCollapseExpanded,
          })}
        >
          <Text id="role" size="text-sm" weight="font-normal" className="text-neutral-dark">
            Role
          </Text>
          <Text size="text-base" weight="font-medium" aria-labelledby="role">
            {listing.roleLabel}
          </Text>
        </div>

        <div className="flex flex-col gap-2 order-3" id="experience">
          <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
            Experience
          </Text>
          <Text size="text-base" weight="font-medium" aria-labelledby="experience">
            {listing.experienceRangeLabel}
          </Text>
        </div>

        <ClaimProtected require={AccountClaim.Staff}>
          <div className="flex flex-col gap-2 order-4">
            <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
              {listing.hourlyRatesEnabled ? 'Hourly budget' : 'Monthly budget'}
            </Text>
            <Text size="text-base" weight="font-medium">
              {listing.budgetLabel}
            </Text>
          </div>
        </ClaimProtected>

        <div className="flex flex-col gap-2 order-4">
          <Text size="text-sm" weight="font-normal" className="text-neutral-dark" id="contract">
            Contract
          </Text>
          <Text size="text-base" weight="font-medium" aria-labelledby="contract">
            {listing.contractDetailsLabel}
          </Text>
        </div>

        <div
          className={cn('flex-col gap-2', {
            'flex order-2 sm:order-4': isCollapseExpanded,
            'hidden sm:flex order-4': !isCollapseExpanded,
          })}
        >
          <Text size="text-sm" weight="font-normal" className="text-neutral-dark" id="openings">
            Openings
          </Text>
          <Text size="text-base" weight="font-medium" aria-labelledby="openings">
            {listing.numberOfOpenings}
          </Text>
        </div>
      </div>

      {!!tags.length && (
        <div className="hidden sm:flex flex-col gap-2">
          <Text size="text-sm" weight="font-normal" className="text-neutral-dark" id="skills">
            Desired skills
          </Text>
          <Tags isReadOnly={true} values={tags} aria-labelledby="skills" />
        </div>
      )}

      {listing.status === ListingStatus.Draft && !isCollapseExpanded && (
        <DraftStatusBanner listing={listing} />
      )}

      <Collapse
        contentOnTop
        initiallyExpanded={initiallyExpanded}
        onStateChange={handleCollapseClick}
        triggerClassName={cn({
          'sm:hidden': disabledDesktopCollapsing,
        })}
      >
        <div className="flex gap-6 flex-col">
          {!!tags.length && (
            <div className="flex sm:hidden flex-col gap-2">
              <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
                Desired skills
              </Text>
              {<Tags isReadOnly={true} values={tags} />}
            </div>
          )}

          {Boolean(listing.detailedRequirements) && (
            <div className="flex flex-col gap-2">
              <Text
                size="text-sm"
                weight="font-normal"
                className="text-neutral-dark"
                id="requirements"
              >
                Detailed requirements
              </Text>
              <Text size="text-base" weight="font-normal" aria-labelledby="requirements">
                {listing.detailedRequirements}
              </Text>
            </div>
          )}

          {Boolean(listing.projectDescription) && (
            <div className="flex flex-col gap-2">
              <Text
                size="text-sm"
                weight="font-normal"
                className="text-neutral-dark"
                id="description"
              >
                Company and project description
              </Text>
              <Text size="text-base" weight="font-normal" aria-labelledby="description">
                {listing.projectDescription}
              </Text>
            </div>
          )}

          {Boolean(listing.listingTerm) && <ListingTermsPreview term={listing.listingTerm} />}

          {showDraftBanner && listing.status === ListingStatus.Draft && isCollapseExpanded && (
            <DraftStatusBanner listing={listing} />
          )}
        </div>
      </Collapse>
    </div>
  )
}
