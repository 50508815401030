export type UpdateScheduleLinksValues = {
  introCallScheduleLink?: string
  finalInterviewScheduleLink?: string
}
export class UpdateScheduleLinksDTO {
  constructor(private readonly props: UpdateScheduleLinksValues) {}

  toJSON() {
    const { introCallScheduleLink, finalInterviewScheduleLink } = this.props
    return {
      introCallScheduleLink: introCallScheduleLink || '',
      finalInterviewScheduleLink: finalInterviewScheduleLink || '',
    }
  }
}
