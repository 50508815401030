import { TrackingEvent } from '../tracking-event'

export class ProfileView implements TrackingEvent {
  name = 'Profile view'

  constructor(
    private id: string,
    private primaryRole: string = 'N/A',
  ) {}

  get payload() {
    return { primaryRole: this.primaryRole, id: this.id }
  }
}
