import { useEffect, useState } from 'react'
import { IconBanner } from 'shared/components/molecules'
import { ReactComponent as WarningIcon } from 'icons/alert-triangle.svg'
import { useDebounce } from 'shared/hooks'

interface ScheduleLinkChangingBannerProps {
  editedScheduleLink: string
  scheduleLink: string
  type: 'intro call' | 'final interview'
  jobTitle: string
}

export const ScheduleLinkChangingBanner = ({
  editedScheduleLink,
  scheduleLink,
  type,
  jobTitle,
}: ScheduleLinkChangingBannerProps) => {
  const [visible, setVisible] = useState(false)

  const { debouncedValue: debouncedEditedScheduleLink } = useDebounce(editedScheduleLink, 500)

  useEffect(() => {
    if (!scheduleLink || !debouncedEditedScheduleLink.trim()) return setVisible(false)
    setVisible(debouncedEditedScheduleLink !== scheduleLink)
  }, [debouncedEditedScheduleLink, scheduleLink])

  if (!visible) return null

  return (
    <IconBanner
      icon={<WarningIcon className="shrink-0 w-6 h-6 stroke-warning-medium" />}
      bgColor="bg-warning-lighter"
    >
      <>
        The scheduling link for the {jobTitle} {type} will change to {debouncedEditedScheduleLink}{' '}
        for future interviews. Any interviews already scheduled will remain on {scheduleLink}.
      </>
    </IconBanner>
  )
}
