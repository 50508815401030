import { useMemo } from 'react'

import { Text } from 'shared/components/atoms'
import { Link } from 'shared/components/atoms'

import { useEnglishEvaluation } from 'candidates-search/exports'
import { AccountClaim, useAuthenticatedUser } from 'shared/hooks'

import { englishResultLabel } from 'vetting/enums'
import { selfEvaluationLabel } from 'modules/candidates/enums'
import { EnglishVetting } from 'modules/candidates/domain'

import { ReactComponent as InProgressIcon } from 'icons/clock-fast-forward.svg'
import { ReactComponent as CompletedIcon } from 'icons/message-text-circle-02.svg'
import { ReactComponent as HoldIcon } from 'icons/advanced-search/english-hold-evaluation.svg'

interface EnglishLevelBadgeProps {
  englishLevel: EnglishVetting
  withIcon?: boolean
  textSize?: 'text-xs' | 'text-sm'
}

export const EnglishLevelBadge = ({
  englishLevel,
  withIcon = true,
  textSize = 'text-xs',
}: EnglishLevelBadgeProps) => {
  const {
    user: { claims },
  } = useAuthenticatedUser()
  const { declaredLevel, level, lastEvaluationId, isInProgress, isReportAvailable } = englishLevel

  const { isEvaluationOnHold } = useEnglishEvaluation()
  const isOnHold = isEvaluationOnHold(englishLevel)

  const evaluationUrl = useMemo(() => {
    if (!lastEvaluationId) return
    if (isReportAvailable)
      return `${window.location.origin}/english-check/evaluations/${lastEvaluationId}`
    return `${window.location.origin}/english-check/evaluations/${lastEvaluationId}/watch`
  }, [isReportAvailable, lastEvaluationId])

  const canAccessEvaluation =
    Boolean(evaluationUrl) && claims.includes(AccountClaim.EnglishCheckViewEvaluation)

  const label = useMemo(() => {
    if (isInProgress) return 'English check in progress'
    if (isOnHold) return 'English check on hold'
    return englishResultLabel[level!] || selfEvaluationLabel[declaredLevel!] || 'Unavailable'
  }, [isInProgress, isOnHold, declaredLevel, level])

  const icon = useMemo(() => {
    if (isInProgress) return <InProgressIcon className="w-4 h-4 stroke-neutral-darkest" />
    if (isOnHold) return <HoldIcon className="w-4 h-4 stroke-neutral-darkest" />
    return <CompletedIcon className="w-4 h-4 stroke-neutral-darkest" />
  }, [isInProgress, isOnHold, label])

  const badge = (
    <>
      {withIcon && icon}
      <Text size={textSize} weight="font-normal">
        {label}
      </Text>
    </>
  )

  if (canAccessEvaluation)
    return (
      <Link
        url={evaluationUrl!}
        target={'_blank'}
        className="shrink-0 flex gap-2 items-center hover:underline"
      >
        {badge}
      </Link>
    )

  return <div className="shrink-0 flex gap-2 items-center">{badge}</div>
}
