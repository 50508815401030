import { useAuthenticatedUser } from 'shared/hooks'
import { useLandingTracking } from 'shared/hooks'
import { useOnboarding } from 'onboarding/hooks'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const PendingOnboardingChecker = () => {
  const { isLoaded, notFound, user } = useAuthenticatedUser()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { seamlessOnboarded } = useOnboarding()
  const { isSdrFlow, getLandingLocation } = useLandingTracking()

  useEffect(() => {
    if (!isLoaded) return

    // @todo: improve route change tracking and redirection
    const isOnbordingRoute = pathname.match(/onboarding$/)
    const isWelcomeRoute = pathname.match(/welcome/)
    const isMissingAccount = !user.role || notFound
    const isReferralsRoute = pathname.match(/referrals/)

    if (seamlessOnboarded) return
    if (isReferralsRoute) return
    if (isOnbordingRoute && user.role) return navigate('/')
    if (isMissingAccount && !isWelcomeRoute && !isOnbordingRoute && !isSdrFlow)
      return navigate('/onboarding')
    if (isMissingAccount && !isWelcomeRoute && !isOnbordingRoute && isSdrFlow)
      return navigate('/employer-onboarding')
    if (!isMissingAccount && isSdrFlow) {
      const urlParams = new URLSearchParams(getLandingLocation())
      const paths = urlParams.get('redirectUrl')?.split('candidates/')
      if (paths?.length && Boolean(paths[1])) return navigate(`/candidates/${paths[1]}`)
    }
  }, [isLoaded, user.role, notFound, pathname])

  return null
}
