import { useMutation } from 'react-query'

import { useMonitoring, useNetwork } from 'shared/hooks'
import { useToast } from 'shared/hooks'
import { HiringMode, useAuthenticatedUser } from 'shared/hooks'
import { CreateJobOfferDTO } from 'modules/hires/dtos'
import { OfferFormValues } from 'modules/hires/domain'
import { useTracking } from 'tracking'
import { JobOfferSent } from 'tracking/events/job-offers'

export const useMakeOffer = (matchingId: string, hiringMode: HiringMode) => {
  const { post } = useNetwork()
  const { toastError } = useToast()
  const { supplementalTermsUrl, user } = useAuthenticatedUser()
  const userFullName = [user.firstName, user.lastName].join(' ')
  const { captureException } = useMonitoring()
  const { trackEvent } = useTracking()

  const { mutateAsync: makeOffer, isLoading } = useMutation(
    async (offerRequest: OfferFormValues) => {
      const request = Object.assign({}, offerRequest, {
        contactFullName: offerRequest.contactFullName || userFullName,
        contactEmail: offerRequest.contactEmail || user.email,
      })

      const payload = new CreateJobOfferDTO(
        matchingId,
        request,
        supplementalTermsUrl,
        hiringMode,
      ).toJSON()

      trackEvent(new JobOfferSent(matchingId))

      await post('hires/job-offer', payload).catch((error: RequestError) => {
        toastError(
          `Error when creating the offer: ${error.response?.data.message ?? 'Unknown error'}`,
        )
        captureException(error)
      })
    },
  )

  return { makeOffer, isRequesting: isLoading }
}
