import { FormEvent } from 'react'

import { PrimaryButton, TertiaryButton } from 'shared/components/atoms'
import { Agreements } from 'account/types'
import { useAuthenticatedUser } from 'shared/hooks'

import styles from './TermsOfService.module.scss'
import { TermsModal } from 'shared/components/molecules'

interface TermsOfServiceProps {
  agreement: Agreements
  title?: string
  onAccept?(): void
  onDisagree?(): void
  onCancel?(): void
  readOnly?: boolean
  redirectUrl?: string
  isLoading?: boolean
}

export const TermsOfService = ({
  agreement,
  onAccept,
  onDisagree,
  onCancel,
  title,
  readOnly,
  redirectUrl = 'https://onstrider.com',
  isLoading,
}: TermsOfServiceProps) => {
  const { user } = useAuthenticatedUser()
  const computedTitle = user.isOnboarded
    ? 'Updated Terms of Service and Privacy Policy'
    : 'Terms of Service and Privacy Policy'

  const handleDisagree = () => {
    if (onDisagree) return onDisagree()
    window.location.assign(redirectUrl)
  }

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault()
    if (onAccept) onAccept()
  }

  const handleCancel = () => onCancel!()

  return (
    <TermsModal
      title={title || computedTitle}
      onCancelClick={readOnly ? handleCancel : handleDisagree}
      content={
        <div
          className={styles.modal__body}
          dangerouslySetInnerHTML={{ __html: agreement.content }}
        />
      }
      footer={
        <>
          {readOnly ? (
            <div className="flex w-full justify-center">
              <PrimaryButton
                className="w-full lg:w-1/2"
                onClick={readOnly ? handleCancel : handleDisagree}
                autoFocus
              >
                Close
              </PrimaryButton>
            </div>
          ) : (
            <form
              className="w-full flex flex-col-reverse lg:flex-row gap-3 lg:gap-6"
              onSubmit={handleFormSubmit}
              noValidate
            >
              <TertiaryButton
                onClick={handleDisagree}
                className="lg:basis-1/2"
                isLoading={isLoading}
              >
                Decline and leave
              </TertiaryButton>
              <PrimaryButton type="submit" className="lg:basis-1/2" autoFocus isLoading={isLoading}>
                Agree and continue
              </PrimaryButton>
            </form>
          )}
        </>
      }
    />
  )
}
