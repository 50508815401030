import classNames from 'classnames'
import { noticePeriodOptions } from '.'
import { Paragraph } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'
import { useMemo } from 'react'
import { cn } from 'modules/shared'

interface Props {
  days: number | null
  showEmpty?: boolean
  className?: string
}

type isMissingNoticePeriod = (value: unknown) => asserts value is number

export const isMissingNoticePeriod = (days: unknown) => {
  return typeof days !== 'number'
}

export const buildNoticePeriodLabel = (days: number | null | undefined) => {
  if (isMissingNoticePeriod(days)) return 'Not informed'

  const weeks = days! / 7
  return noticePeriodOptions.find(({ id }) => id === weeks)?.label || 'Not informed'
}

export const NoticePeriod = ({ days, showEmpty, className }: Props) => {
  const missingValue = useMemo<boolean>(() => isMissingNoticePeriod(days), [days])
  if (!showEmpty && days === null) return null

  return (
    <div className={cn('w-fit flex flex-col items-stretch', className)}>
      <Paragraph
        size="body-sm"
        weight="font-normal"
        className="text-neutral-dark !whitespace-nowrap"
      >
        Ready to start
      </Paragraph>
      <Text
        size="text-lg"
        weight="font-normal"
        className={classNames('!whitespace-nowrap', {
          'text-neutral-medium': missingValue,
          'text-neutral-darkest': !missingValue,
        })}
      >
        {buildNoticePeriodLabel(days)}
      </Text>
    </div>
  )
}
