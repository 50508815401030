import { useContext, useEffect, useMemo, useState } from 'react'

import { SearchContext } from 'candidates-search/contexts'

import { Tab } from 'candidates-search/enums'
import { MatchingInfo } from 'candidates-search/domain'
import { MatchingStatus } from 'matching/enums'

export const SEARCH_MAX_SIZE = Number(process.env.REACT_APP_SEARCH_MAX_SIZE ?? 10000)

export interface SearchTab {
  tab: Tab
  label: string
  counter: number
  total?: number
  visible: boolean
  isMaximum?: boolean
}

export const useTabs = () => {
  const { search, searchResult, tab: activeTab } = useContext(SearchContext)
  const matchings = search?.matchings || []

  const [searchTabCounter, setSearchTabCounter] = useState(0)

  const tabItems = useMemo<Record<Tab, Array<string>>>(() => {
    let screening: Array<string> = [],
      saved: Array<string> = [],
      doubleChecking: Array<string> = [],
      shortlisted: Array<string> = [],
      matched: Array<string> = [],
      notAFit: Array<string> = []

    matchings.forEach((matching) => {
      if (matching.status === MatchingStatus.Saved) {
        return saved.push(matching.profileId)
      }

      if ([MatchingStatus.Screening, MatchingStatus.ScreeningApproved].includes(matching.status)) {
        return screening.push(matching.profileId)
      }

      if (matching.status === MatchingStatus.DoubleChecking) {
        return doubleChecking.push(matching.profileId)
      }

      if (matching.status === MatchingStatus.Shortlisted) {
        return shortlisted.push(matching.profileId)
      }

      if (matching.clientFacing) {
        return matched.push(matching.profileId)
      }

      if (matching.status === MatchingStatus.NotAFit) {
        return notAFit.push(matching.profileId)
      }
    })

    return {
      [Tab.Screening]: screening,
      [Tab.Saved]: saved,
      [Tab.DoubleChecking]: doubleChecking,
      [Tab.Shortlisted]: shortlisted,
      [Tab.Matched]: matched,
      [Tab.NotAFit]: notAFit,
    } as Record<Tab, Array<string>>
  }, [matchings])

  const getCounters = (tab: Tab) => {
    if (tab === activeTab) {
      return {
        counter: searchResult.totalRecords,
        total: tabItems[tab].length,
      }
    }

    return {
      counter: tabItems[tab].length,
      total: undefined,
    }
  }

  useEffect(() => {
    if (activeTab === Tab.AllCandidates) setSearchTabCounter(searchResult.totalRecords)
  }, [searchResult.totalRecords])

  const tabs = useMemo<Array<SearchTab>>(() => {
    return [
      {
        tab: Tab.AllCandidates,
        label: 'Search candidates',
        counter: searchTabCounter,
        visible: true,
        isMaximum: searchTabCounter >= SEARCH_MAX_SIZE,
      },
      {
        tab: Tab.Saved,
        label: 'Saved',
        ...getCounters(Tab.Saved),
        visible: Boolean(search?.isListingLinked),
      },
      {
        tab: Tab.Screening,
        label: 'Screening',
        ...getCounters(Tab.Screening),
        visible: Boolean(search?.isListingLinked),
      },
      {
        tab: Tab.DoubleChecking,
        label: 'Double-checking',
        ...getCounters(Tab.DoubleChecking),
        visible: Boolean(search?.isListingLinked),
      },
      {
        tab: Tab.Shortlisted,
        label: 'Shortlisted',
        ...getCounters(Tab.Shortlisted),
        visible: Boolean(search?.isListingLinked),
      },
      {
        tab: Tab.Matched,
        label: 'Matched',
        ...getCounters(Tab.Matched),
        visible: Boolean(search?.isListingLinked),
      },
      {
        tab: Tab.NotAFit,
        label: 'Not a fit',
        ...getCounters(Tab.NotAFit),
        visible: Boolean(search?.isListingLinked),
      },
    ].filter(({ visible }) => visible)
  }, [search, searchResult, searchTabCounter])

  const getMatchingTab = (matching?: MatchingInfo) => {
    if (!matching) return Tab.AllCandidates
    if (matching.clientFacing) return Tab.Matched

    return {
      [MatchingStatus.Saved]: Tab.Saved,
      [MatchingStatus.Screening]: Tab.Screening,
      [MatchingStatus.ScreeningApproved]: Tab.Screening,
      [MatchingStatus.DoubleChecking]: Tab.DoubleChecking,
      [MatchingStatus.Shortlisted]: Tab.Shortlisted,
      [MatchingStatus.Matched]: Tab.Matched,
      [MatchingStatus.IntroCallRequested]: Tab.Matched,
      [MatchingStatus.FinalInterviewRequested]: Tab.Matched,
      [MatchingStatus.Hired]: Tab.Matched,
      [MatchingStatus.Rejected]: Tab.Matched,
      [MatchingStatus.NotAFit]: Tab.NotAFit,
    }[matching?.status]
  }

  return { tabs, tabItems, getMatchingTab }
}
