import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { FormActions, JobListingTerms, JobSpecifications, Wrapper } from './components'
import { Teammates } from '../Teammates'
import { TalentReviewToggle } from '../JobListingFields'
import { Subscribers } from '../Subscribers'

import { useAuthenticatedUser, useForm, usePageTitle, useToast } from 'shared/hooks'
import { useListing } from 'job-listing/hooks'
import { useFetchCompanyInfo } from 'company/exports'

import { schema } from './schema'

import { EngagementType, ListingStatus, PaymentModel } from 'job-listing/enums'
import { FormListing } from 'job-listing/domains'
import { Teammate } from 'company/domain'

export const ListingForm = ({
  initialListing,
  teammates,
  setTeammates,
  isOnboarding,
  onCancel,
  onSave,
  autoFocusFirstInput = false,
}: {
  initialListing?: FormListing
  teammates?: Array<Teammate>
  setTeammates?: (newTeammates: Array<Teammate>) => void
  isOnboarding?: boolean
  onCancel?: () => void
  onSave?: () => void
  autoFocusFirstInput?: boolean
}) => {
  const { user } = useAuthenticatedUser()
  const navigate = useNavigate()
  const { createListing, publishListing, isSaving, isPublishing } = useListing()
  const { toastSuccess } = useToast()
  const { company } = useFetchCompanyInfo()
  const employees = useMemo(() => company?.employees || [], [company])

  const skipTermsAcceptance = Boolean(user.company?.canSkipListingTerms)
  const isEditing = Boolean(initialListing?.id)
  const isCreating = isEditing === false
  const isActive = initialListing?.status === ListingStatus.Active
  const title = isEditing ? 'Edit job listing' : 'New job listing'
  const primaryActionLabel = () => {
    if (isOnboarding) return 'Save and confirm'
    if (isActive) return 'Save changes'
    return 'Publish'
  }

  const formControl = useForm({
    schema,
    defaultValues: {
      numberOfOpenings: 1,
      stacks: [],
      paymentModel: PaymentModel.Monthly,
      salaryFrom: user.isStriderStaff ? undefined : 1,
      salaryTo: user.isStriderStaff ? undefined : 1,
      experienceRange: {
        min: initialListing?.experienceRange?.min || 0,
        max: initialListing?.experienceRange?.max || 10,
      },
      engagementType: EngagementType.LongTerm,
      freelancingWorkingHoursPerWeek: 5,
      acceptTerms: false,
      subscribers: initialListing?.subscribers ?? [],
      isTalentReview: false,
    },
  })
  const { register, handleSubmit, getError, watch, setValue, getValues, reset, isDirty } =
    formControl

  usePageTitle(title)

  const jobTitle = watch('jobTitle')
  const roleId = watch('roleId')
  const subscribers: Array<{ id: number; label: string }> = watch('subscribers')

  const hasMinimumData = jobTitle && roleId
  const draftable = Boolean(!isActive && hasMinimumData && !isOnboarding)
  const savable = Boolean(isActive && hasMinimumData)

  const handleSaveAndPublish = () => {
    const payload = getValues() as FormListing

    createListing(payload).then((id) => {
      if (!id) return // creation failed

      const termId = initialListing?.termId || payload.termId
      if (isOnboarding) onSave && onSave()
      return publishListing({ id, termId }).then(() => {
        if (isOnboarding) return

        toastSuccess('Listing published.')
        navigate(`/listings/${id}/matchings`)
      })
    })
  }

  const handleSubmissionAttempt = handleSubmit(handleSaveAndPublish)

  const handleCancel = () => {
    if (isOnboarding) return onCancel && onCancel()
    initialListing?.id ? navigate(`/listings/${initialListing?.id}`) : navigate('/')
  }

  const handleDraft = () => {
    const payload = getValues() as FormListing

    return createListing(payload).then((id) => {
      if (!id) return // creation failed

      toastSuccess('Job listing saved.')
      return navigate(`/listings/${id}`)
    })
  }

  const handleLeavingSave = () => {
    if (isCreating) {
      return handleDraft()
    }

    return handleSaveAndPublish()
  }

  useEffect(() => {
    if (!subscribers?.length && employees.length && isCreating) {
      const defaultEmployee = employees.find(({ email }) => email === user.email)!
      setValue(
        'subscribers',
        subscribers.concat([{ id: defaultEmployee.id, label: defaultEmployee.email }]),
      )
    }
  }, [subscribers, employees])

  useEffect(() => reset(initialListing), [initialListing?.id])

  useEffect(() => {
    setValue('canSkipTermsAcceptance', skipTermsAcceptance, { shouldValidate: true })
  }, [skipTermsAcceptance])

  useEffect(() => {
    if (isOnboarding)
      setValue(
        'subscribers',
        employees.map(({ id, email }) => ({ id, label: email })),
      )
  }, [employees])

  return (
    <Wrapper title={title} isOnboarding={isOnboarding}>
      <form onSubmit={handleSubmissionAttempt} noValidate>
        <JobSpecifications
          autoFocusFirstInput={isCreating || autoFocusFirstInput}
          formControl={formControl}
          initialListing={initialListing}
        />

        {isOnboarding ? (
          <Teammates teammates={teammates!} setTeammates={setTeammates!} />
        ) : (
          <Subscribers
            employees={employees}
            subscribers={subscribers}
            getError={getError}
            setValue={setValue}
          />
        )}

        <TalentReviewToggle formControl={formControl} />

        <JobListingTerms
          currentListingTerm={initialListing?.listingTerm}
          skipTermsAcceptance={skipTermsAcceptance}
          getError={getError}
          register={register}
          setValue={setValue}
        />

        <FormActions
          onLeavingSave={handleLeavingSave}
          savable={savable}
          isDirty={isDirty}
          onCancel={handleCancel}
          onDraft={handleDraft}
          primaryActionLabel={primaryActionLabel()}
          draftable={draftable}
          isOnboarding={isOnboarding}
          isLoading={isSaving || isPublishing}
        />
      </form>
    </Wrapper>
  )
}
