import { TrackingEvent } from '../tracking-event'

export class MatchingIntroCallRequested implements TrackingEvent {
  name = 'Matching request intro call'

  constructor(
    private readonly matchingId: string,
    private readonly jobListingId: string,
  ) {}

  get payload() {
    return { matchingId: this.matchingId, jobListingId: this.jobListingId }
  }
}
