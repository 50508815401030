import { Matching } from 'matching/domain'
import { MatchingStatus } from 'matching/enums'
import { useAuthenticatedUser } from 'shared/hooks'

interface Props {
  jobOffersEnabled: boolean
  matching: Matching
}

export const useHireEnabled = ({ jobOffersEnabled, matching }: Props) => {
  const {
    user: { isManager },
  } = useAuthenticatedUser()

  return Boolean(
    jobOffersEnabled &&
      matching.hiringCost &&
      matching.status !== MatchingStatus.Hired &&
      isManager,
  )
}
