import { toast, ToastContent, ToastOptions } from 'react-toastify'

export const useToast = () => {
  return {
    toastError: (content: ToastContent, options?: Optional<ToastOptions<{}>>) =>
      toast.error(content || 'Something went wrong.', options),
    toastInfo: toast.info,
    toastSuccess: toast.success,
  }
}
