import { useEffect, useState } from 'react'

import { Modal } from 'shared/components/molecules'
import { DeadlineProgressBar } from 'job-opportunity/components'
import { JobDescription } from 'screening/exports'
import {
  B1ApplicantMisalignment,
  Footer,
  JobOpportunityOnHold,
  MisalignmentBanner,
} from './components'

import { JobOpportunity } from 'job-opportunity/domain'
import { VettingPackageResponse } from 'vetting/domain'

import { useInterval } from 'shared/hooks'
import { useCompensation } from 'hooks/payRate'

interface Props {
  isOpen: boolean
  jobOpportunity: JobOpportunity
  vetting: Nullable<VettingPackageResponse>
  onClose: () => void
  onReject: () => void
  onApprove: () => void
  refetchJobOpportunity: () => void
}

export const JobOpportunityModal = ({
  isOpen,
  jobOpportunity,
  vetting,
  onClose,
  onReject,
  onApprove,
  refetchJobOpportunity,
}: Props) => {
  const [disableInterested, setDisableInterested] = useState(false)
  const [now, setNow] = useState(new Date())

  const { clear } = useInterval(() => setNow(new Date()), 1000)

  useEffect(() => clear, [])

  const {
    isPending,
    screening: { minimumPayRate, maximumPayRate, paymentModel },
    compensation,
  } = jobOpportunity

  const { compensationLabel, compensationTitle } = useCompensation({
    compensation,
    minimumPayRate,
    maximumPayRate,
    paymentModel,
  })

  const handleB1ApplicantConfirmation = (value: boolean) => setDisableInterested(!value)

  useEffect(() => {
    const onFocus = () => {
      if (!isOpen) return
      refetchJobOpportunity()
    }

    window.addEventListener('focus', onFocus)
    return () => window.removeEventListener('focus', onFocus)
  }, [isOpen])

  useEffect(() => {
    if (!isOpen) return
    refetchJobOpportunity()
  }, [isOpen])

  return (
    <Modal
      open={isOpen}
      handleClose={onClose}
      title={isPending ? 'New job opportunity' : 'Job opportunity'}
      contentContainerClassName="!py-10"
      content={
        <JobDescription
          jobDescription={jobOpportunity.jobDescription}
          title={jobOpportunity.jobDescription.title}
          compensationTitle={compensationTitle}
          compensationLabel={compensationLabel}
          showMisalignments={jobOpportunity.isPending}
          hideDescription={disableInterested}
          headerChildren={
            <>
              {isPending ? (
                <DeadlineProgressBar jobOpportunity={jobOpportunity} now={now} />
              ) : undefined}
              <JobOpportunityOnHold jobOpportunity={jobOpportunity} vetting={vetting} />
            </>
          }
        >
          <>
            <MisalignmentBanner
              jobDescription={jobOpportunity.jobDescription}
              showMisalignments={jobOpportunity.isPending}
            />

            <B1ApplicantMisalignment
              showMisalignments={jobOpportunity.isPending}
              jobDescription={jobOpportunity.jobDescription}
              onConfirm={handleB1ApplicantConfirmation}
            />
          </>
        </JobDescription>
      }
      footer={
        <Footer
          onReject={onReject}
          onApprove={onApprove}
          onClose={onClose}
          isPending={isPending}
          disableInterested={disableInterested}
        />
      }
    />
  )
}
