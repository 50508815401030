import { useMonitoring } from 'shared/hooks'
import { useNetwork } from 'shared/hooks'
import { useToast } from 'shared/hooks'
import { useMutation } from 'react-query'
import { ScreeningSharingSelection } from 'screening/pages/Screening/components/JobDescription/components/ScreeningSharingForm'

export const usePublishScreening = (listingId: string, screeningId: string) => {
  const { post } = useNetwork()
  const { toastSuccess, toastError } = useToast()
  const { captureException } = useMonitoring()

  const { mutateAsync: publish, isLoading } = useMutation(
    async (selection: ScreeningSharingSelection) =>
      post(`listings/${listingId}/screenings/${screeningId}/publish`, selection),
    {
      retry: (failureCount, error: RequestError) =>
        error.response?.status >= 400 && error.response?.status <= 499 && failureCount < 2,
      onSuccess: () => {
        toastSuccess('Screening published.')
      },
      onError: (error: RequestError) => {
        toastError(
          `Failed to publish the screening: ${error.response?.data.message ?? 'Unknown error'}`,
        )
        captureException(error)
      },
    },
  )

  return { publish, isLoading }
}
