import { useContext, useEffect } from 'react'

import { Avatar } from 'shared/components/atoms'
import { Paragraph } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'
import { FeaturedSlides } from 'shared/components/molecules'

import { REFERRAL_ARTICLES_IDS } from 'pages/Refer/consts'
import { CandidateContext } from 'contexts/candidate'
import { useHelp } from 'modules/help'
import { useInstructionsModal } from 'pages/Refer/hooks/useInstructionsModal'
import { Recruiter } from 'modules/recruiter'
import { useAuthenticatedUser } from 'shared/hooks'

const Slide = ({
  title,
  description,
  img,
  children,
  avatarPhoto,
  avatarText,
}: {
  title: string
  description: string
  img: string
  children?: React.ReactNode
  avatarPhoto?: string
  avatarText?: string
}) => (
  <div>
    <div
      className="h-[270px] flex items-center justify-center bg-center bg-cover"
      style={{
        ...{ backgroundImage: `url(${img}_3x.png)` },
        ...{ backgroundImage: `-image-set(url(${img}_3x.png) 3x, url(${img}_4x.png) 4x)` },
        ...{ backgroundImage: `-webkit-image-set(url(${img}_3x.png) 3x, url(${img}_4x.png) 4x)` },
      }}
    >
      {Boolean(avatarPhoto || avatarText) && (
        <Avatar photo={avatarPhoto} text={avatarText!} size="lg" />
      )}
    </div>
    <div className="pt-10 px-6 md:px-12 pb-12 flex flex-col items-start gap-4 md:h-[232px]">
      <Text size="text-2xl" weight="font-medium" className="text-neutral-darkest">
        {title}
      </Text>
      <Paragraph size="body-md" weight="font-normal" className="text-neutral-darkest">
        {description}
        {children}
      </Paragraph>
    </div>
  </div>
)

export const InstructionsModal = ({
  children,
  recruiter,
}: {
  children?: JSX.Element
  recruiter?: Recruiter
}) => {
  const {
    user: { isSeeker },
  } = useAuthenticatedUser()
  const {
    candidate: { photoUrl, firstName },
    loadCandidate,
  } = useContext(CandidateContext)

  const { showArticle } = useHelp()
  const { watchedInstructions, onWatch } = useInstructionsModal()

  useEffect(() => {
    isSeeker && loadCandidate()
  }, [isSeeker])

  const showReferQualificationGuideArticle = () => {
    showArticle(
      isSeeker
        ? REFERRAL_ARTICLES_IDS.JobSeekerQualificationGuide
        : REFERRAL_ARTICLES_IDS.RecruiterQualificationGuide,
    )
  }

  const steps = [
    {
      key: 1,
      visible: true,
      component: (
        <Slide
          key={1}
          img="/images/refer/refer-instructions-frame-1"
          title="Welcome to Refer"
          description="We're glad to have you onboard. Here's a walkthrough of what to expect when using Strider Refer to get you up and running."
          avatarPhoto={photoUrl || undefined}
          avatarText={recruiter?.firstName || firstName}
        />
      ),
    },
    {
      key: 2,
      visible: !recruiter?.slug,
      component: (
        <Slide
          key={2}
          img="/images/refer/refer-instructions-frame-2"
          title="Set up your referral link"
          description="You'll need to create a unique referral link. This is the first step to get started referring talent to Strider."
        />
      ),
    },
    {
      key: 3,
      visible: true,
      component: (
        <Slide
          key={3}
          img="/images/refer/refer-instructions-frame-3"
          title="Start referring talent"
          description="Share your unique link with the person you want to refer to Strider. To get credit for a referral, they need to sign up to Strider using your unique link."
        />
      ),
    },
    {
      key: 4,
      visible: true,
      component: (
        <Slide
          key={4}
          img="/images/refer/refer-instructions-frame-4"
          title="Get paid for qualified referrals"
          description=""
        >
          <div className="items-center gap-1 cursor-pointer">
            You earn whenever talent you referred signs up and completes their profile and then
            again when they get hired. Check out how much you can earn on the{' '}
            <span
              className="text-info-medium"
              onClick={() => showArticle(REFERRAL_ARTICLES_IDS.PaymentGuide)}
            >
              Refer Payment Guide
            </span>{' '}
            and read about what talent qualifies on the{' '}
            <span className="text-info-medium" onClick={showReferQualificationGuideArticle}>
              Refer Qualification Guide.
            </span>
          </div>
        </Slide>
      ),
    },
  ].filter(({ visible }) => visible)

  return (
    <FeaturedSlides
      headerImages={{}}
      trigger={children}
      open={!watchedInstructions}
      steps={steps.length}
      nextLabel="Next"
      backLabel="Back"
      confirmLabel="Got it"
      cancelLabel="I'll see it later"
      onConfirm={onWatch}
    >
      {steps.map(({ component }) => component)}
    </FeaturedSlides>
  )
}
