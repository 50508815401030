import qs from 'qs'
import { Type, plainToInstance } from 'class-transformer'

import { HiringMode } from 'shared/hooks'
import { ListingStatus } from 'job-listing/enums'
import { MatchingStatus } from 'matching/enums'

import { Filter, defaultFilter } from './filter.domain'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const decoder = (value: string): any => {
  if (/^(-?\d+|-?\d*\.\d+)$/.test(value)) {
    return parseFloat(value)
  }

  const keywords = {
    true: true,
    false: false,
    null: null,
    undefined: undefined,
    Infinity: Infinity,
  }

  if (value in keywords) {
    let property = value as keyof typeof keywords
    return keywords[property]
  }

  return decodeURI(value)
}

export interface CompanyInfo {
  id: number
  name: string
  hiringMode: HiringMode
  annualFee: Nullable<number>
  hourlyFee: Nullable<number>
}

export interface MatchingInfo {
  matchingId: string
  profileId: string
  status: MatchingStatus
  clientFacing: boolean
  lastSyncedAt: Nullable<string>
  firstSharedAt: Nullable<string>
  isUnavailable: boolean
}

export class ListingInfo {
  id: string
  title: string
  company: CompanyInfo
  matchings: Array<MatchingInfo>
  maximumSalary: Nullable<number>
  experienceRangeLabel: Nullable<string>
  status: ListingStatus
  isHourlyRatesEnabled: boolean
}

export class Search {
  id: Optional<string>
  title: string
  url: string
  listing: Nullable<ListingInfo>
  published: boolean
  activeCandidatesCount: number

  get isListingLinked() {
    return Boolean(this.listing)
  }

  get matchings() {
    return this.listing?.matchings || []
  }

  get filter(): Filter {
    const parsedUrl = decodeURIComponent(this.url)
    const decode = qs.parse(parsedUrl.slice(1), { decoder }) as Partial<Filter>

    return plainToInstance(Filter, {
      keywords: decode.keywords || defaultFilter.keywords,
      roles: decode.roles || [] || defaultFilter.roles,
      skills: { stacks: [], matchAll: false, ...decode.skills },
      payRate: decode.payRate || defaultFilter.payRate,
      yearsOfExperience: decode.yearsOfExperience || defaultFilter.yearsOfExperience,
      onlyVerifiedEnglishLevel:
        decode.onlyVerifiedEnglishLevel || defaultFilter.onlyVerifiedEnglishLevel,
      onlyTalentWithoutActiveJob:
        decode.onlyTalentWithoutActiveJob ?? defaultFilter.onlyTalentWithoutActiveJob,
      onlyTalentWithAdvertiseConsent:
        decode.onlyTalentWithAdvertiseConsent ?? defaultFilter.onlyTalentWithAdvertiseConsent,
      onlyTalentWithPersonalWebsite:
        decode.onlyTalentWithPersonalWebsite ?? defaultFilter.onlyTalentWithPersonalWebsite,
      englishLevel: this.getDecodedEnglishLevel(decode),
      profileFulfillmentStatus:
        decode.profileFulfillmentStatus || defaultFilter.profileFulfillmentStatus,
      profileStatus: decode.profileStatus || defaultFilter.profileStatus,
      staStatus: decode.staStatus || defaultFilter.staStatus,
      educationDegree: decode.educationDegree || defaultFilter.educationDegree,
      restrictTierToBachelors:
        decode.restrictTierToBachelors || defaultFilter.restrictTierToBachelors,
      institutionTier: decode.institutionTier || defaultFilter.institutionTier,
      engagementPreferences: decode.engagementPreferences || defaultFilter.engagementPreferences,
      country: decode.country ?? defaultFilter.country,
      assessmentTags: {
        mustHave: decode.assessmentTags?.mustHave || defaultFilter.assessmentTags.mustHave,
        mustNotHave: decode.assessmentTags?.mustNotHave || defaultFilter.assessmentTags.mustNotHave,
      },
      proficiencies: {
        mustHave: decode.proficiencies?.mustHave || defaultFilter.proficiencies.mustHave,
        mustNotHave: decode.proficiencies?.mustNotHave || defaultFilter.proficiencies.mustNotHave,
      },
      primarySkills: {
        stacks: decode.primarySkills?.stacks || defaultFilter.primarySkills.stacks,
        matchAll: decode.primarySkills?.matchAll || defaultFilter.primarySkills.matchAll,
      },
      mockInterviewDone: decode.mockInterviewDone || defaultFilter.mockInterviewDone,
      screeningProgress: decode.screeningProgress || defaultFilter.screeningProgress,
    })
  }

  getMatchingByProfileId(id: string) {
    return this.matchings.find(({ profileId }) => profileId === id)
  }

  getDecodedEnglishLevel(decode: Partial<Filter>) {
    const decodedEnglishLevel = decode.englishLevel

    if (!decodedEnglishLevel) return defaultFilter.englishLevel

    const { proficient, basic, independent } = decodedEnglishLevel as {
      proficient?: boolean
      basic?: boolean
      independent?: boolean
    }

    return {
      C1: decodedEnglishLevel.C1 || Boolean(proficient),
      C2: decodedEnglishLevel.C2 || Boolean(proficient),
      B1: decodedEnglishLevel.B1 || Boolean(independent),
      B2: decodedEnglishLevel.B2 || Boolean(independent),
      A1: decodedEnglishLevel.A1 || Boolean(basic),
      A2: decodedEnglishLevel.A2 || Boolean(basic),
    }
  }
}

export class GroupedSearches {
  groupName: string

  @Type(() => Search)
  searches: Array<Search>
}
