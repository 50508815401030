import { useMutation } from 'react-query'
import { useMonitoring, useNetwork, useToast } from 'shared/hooks'
import { useTracking } from 'tracking'
import { MatchingIntroCallRequested } from 'tracking/events/matchings'

interface Props {
  matchingId: string
  jobListingId: string
}

export const useRequestIntroCall = ({ matchingId, jobListingId }: Props) => {
  const { post } = useNetwork()
  const { toastError, toastSuccess } = useToast()
  const { captureException } = useMonitoring()
  const { trackEvent } = useTracking()

  const { mutateAsync: requestIntroCall, isLoading } = useMutation(
    () => {
      trackEvent(new MatchingIntroCallRequested(matchingId, jobListingId))
      return post(`/listings/${jobListingId}/matchings/${matchingId}/intro-call`)
    },
    {
      onSuccess: () => {
        toastSuccess('Intro call requested!')
      },
      onError: (error: RequestError) => {
        toastError(
          `Could not request the intro call: ${error.response?.data.message ?? 'Unknown error'}`,
        )
        captureException(error)
      },
    },
  )

  return { requestIntroCall, isLoading }
}
