import { Provider, Root, Trigger, Portal, Content } from '@radix-ui/react-tooltip'
import { Text } from 'components/Text'
import { cn } from 'modules/shared'

interface Props {
  children: React.ReactNode
  content: string | JSX.Element
  className?: string
  childrenClassName?: string
  isVisible?: boolean
  delayDuration?: number
}

export const Tooltip = ({
  children,
  content,
  className,
  childrenClassName,
  isVisible = true,
  delayDuration = 700,
}: Props) => {
  if (!isVisible) return null

  return (
    <Provider delayDuration={delayDuration}>
      <Root>
        <Trigger asChild>
          <div className={cn('w-fit', childrenClassName)}>{children}</div>
        </Trigger>
        <Portal>
          <Content
            className={cn(
              'bg-neutral-darker rounded py-1 px-2 select-none shadow-[0_4px_8px_rgba(0, 0, 0, 0.08)] mb-0.5 z-50',
              className,
            )}
          >
            {typeof content === 'string' ? (
              <Text size="caption" color="text-neutral-day">
                {content}
              </Text>
            ) : (
              content
            )}
          </Content>
        </Portal>
      </Root>
    </Provider>
  )
}
