import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Spinner } from 'shared/components/atoms'
import { useJobListingApplication } from 'hooks/applications'
import { useAuthenticatedUser } from 'shared/hooks'

export const RedirectCallback = () => {
  const { jobListing } = useJobListingApplication()
  const { user, isLoaded } = useAuthenticatedUser()

  const urlParams = new URLSearchParams(window.location.search)
  // For some reason Clerk is chaining url params
  const doubleQuestionMarkRedirect = window.location.href
    .split(/redirect_url=/)[1]
    ?.split('%2F')
    .pop()
  const redirectUrl = doubleQuestionMarkRedirect || urlParams.get('redirectUrl') || ''
  const navigate = useNavigate()

  useEffect(() => {
    if (doubleQuestionMarkRedirect) return navigate(`/${decodeURIComponent(redirectUrl)}`)
    if (!isLoaded) return
    if (jobListing && user.isSeeker) return navigate(`/?job_listing=${jobListing}`)
    navigate(`/${decodeURIComponent(redirectUrl)}`)
  }, [redirectUrl, jobListing, user, isLoaded, doubleQuestionMarkRedirect])

  return <Spinner />
}
