import { useMutation, useQueryClient } from 'react-query'

import { ProficiencyTag } from 'modules/profiles/domain'
import { AddProficienciesDTO } from 'modules/profiles/dtos/proficiencies'
import { useMonitoring, useNetwork, useToast } from 'shared/hooks'

export const useAddProficiency = (profileId: string) => {
  const { patch } = useNetwork()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()
  const client = useQueryClient()

  const { mutateAsync: addProficiencies, isLoading } = useMutation(
    (proficiencies: Array<ProficiencyTag>) => {
      const payload = new AddProficienciesDTO(proficiencies).toJSON()
      return patch(`profile/${profileId}/proficiencies`, payload)
    },
    {
      onSuccess: () => {
        client.invalidateQueries(`candidates/${profileId}`)
      },
      onError: (error: RequestError) => {
        toastError(
          `Failed to add proficiencies: ${error.response?.data.message ?? 'Unknown error'}`,
        )
        captureException(error)
      },
    },
  )

  return { addProficiencies, isLoading }
}
