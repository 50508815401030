import { Avatar } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'

import { useAuthenticatedUser } from 'shared/hooks'
import { useFormatting } from 'shared/hooks'

import { MatchingSettings } from 'matching/domain'

interface LastEditorProps {
  matchingSettings: MatchingSettings
}

export const LastEditor = ({ matchingSettings }: LastEditorProps) => {
  const { formatDateTime } = useFormatting()
  const {
    user: { email },
  } = useAuthenticatedUser()

  if (!matchingSettings.updatedAt || !matchingSettings.updatedBy) return null
  const isMe = email === matchingSettings.updatedBy.email
  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-row items-center gap-2">
        <Avatar size="sm" text={matchingSettings.updatedBy.email} />
        <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
          Last edited by{' '}
          <Text size="text-sm" weight="font-medium" className="text-neutral-darkest">
            {isMe ? 'You' : matchingSettings.updatedBy.email}
          </Text>
        </Text>
      </div>
      <Text size="text-xs" weight="font-normal" className="text-neutral-dark">
        {formatDateTime({ date: new Date(matchingSettings.updatedAt) })}
      </Text>
    </div>
  )
}
